import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  alpha,
  Box,
  Chip,
  CircularProgress,
  darken,
  Dialog,
  DialogActions,
  lighten,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import { BiChevronLeft, BiChevronRight, BiTrash } from 'react-icons/bi';
import { useParams } from 'react-router-dom';

import { theme } from '../../../theme';
import { capitalizeFullName } from '../../../components/Utils/Formating';
import {
  deleteAllCustomerScans,
  deleteCustomerScan,
  getCustomer,
} from '../../../actions/userActions';
import {
  calculateTotalPages,
  calculateTotalScans,
  CustomIconButton,
  hasRoles,
  StyledButton,
  UserRoles,
} from '../../../components/Utils/UIUtils';

export const CustomerById = () => {
  const { t, i18n } = useTranslation();
  const { customer, loading, error } = useSelector((state) => state.customer);
  const deleteAllScansState = useSelector(
    (state) => state.deleteAllCustomerScans
  );
  const deleteScanState = useSelector((state) => state.deleteCustomerScan);
  const userAuth = useSelector((state) => state.userLogin.userAuth);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [data, setData] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [scanToDelete, setScanToDelete] = useState(null);
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    if (id) dispatch(getCustomer(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (customer) {
      const scansData = Object.entries(customer.scans || {})
        .flatMap(([monthKey, scans]) => {
          return scans.map((scan) => ({
            ...scan,
            monthKey,
            timestamp: scan.timestamp,
            date: new Date(scan.timestamp).toLocaleDateString(
              i18n.language === 'it-IT' ? 'it-IT' : 'en-US'
            ),
            time: new Date(scan.timestamp).toLocaleTimeString(
              i18n.language === 'it-IT' ? 'it-IT' : 'en-US',
              {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }
            ),
          }));
        })
        .sort((a, b) => b.timestamp - a.timestamp)
        .slice((page - 1) * pageSize, page * pageSize);

      setData(
        scansData.map((scan) =>
          convertCustomerScanToData(userAuth?.user, scan, t, handleDeleteClick)
        )
      );
    }

    if (loading) {
      const skeletonData = Array.from({ length: pageSize }, () =>
        convertCustomerScanToSkeleton()
      );
      setData(skeletonData);
    }
  }, [customer, page, loading, t, i18n.language, userAuth?.user]);

  const handleDeleteClick = (scan) => {
    setScanToDelete(scan);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (scanToDelete) {
      await dispatch(
        deleteCustomerScan(id, scanToDelete.monthKey, {
          timestamp: scanToDelete.timestamp,
          lessonsLeft: scanToDelete.lessonsLeft,
        })
      );
      setDeleteDialogOpen(false);
      setScanToDelete(null);
    }
  };

  const columns = [
    {
      name: 'date',
      label: t('ADMIN.CUSTOMER_BY_ID.TABLE.DATE'),
      options: {
        setCellProps: () => ({ style: { width: '200px' } }),
        filter: false,
        sort: false,
      },
    },
    {
      name: 'time',
      label: t('ADMIN.CUSTOMER_BY_ID.TABLE.TIME'),
      options: {
        setCellProps: () => ({ style: { width: '200px' } }),
        filter: false,
        sort: false,
      },
    },
    ...(hasRoles(userAuth.user, [UserRoles.SUPER])
      ? [
          {
            name: 'actions',
            label: t('ADMIN.CUSTOMER_BY_ID.TABLE.ACTIONS'),
            options: {
              filter: false,
              sort: false,
            },
          },
        ]
      : []),
  ];

  const options = {
    download: false,
    elevation: 0,
    print: false,
    filter: false,
    search: false,
    selectableRows: 'none',
    viewColumns: false,
    rowsPerPageOptions: [],
    responsive: 'standard',
    caseSensitive: false,
    customFooter: () => null,
    textLabels: {
      body: {
        noMatch: t('ADMIN.CUSTOMER_BY_ID.TABLE.NO_MATCH'),
      },
    },
  };

  const handleDeleteAllScans = async () => {
    try {
      await dispatch(deleteAllCustomerScans(id));
      setDeleteAllDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const totalScans = calculateTotalScans(customer?.scans);
  const totalPages = calculateTotalPages(totalScans, pageSize);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return error ? (
    <Alert severity="error">{error}</Alert>
  ) : (
    <Stack gap={2}>
      <Stack
        direction={'row'}
        gap={2}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <CustomIconButton onClick={() => window.history.back()}>
            <BiChevronLeft size={18} />
          </CustomIconButton>
          <Typography
            variant="h1"
            sx={{
              fontSize: '20px',
              color: 'primary.neutral900',
              fontWeight: '500',
            }}
          >
            <Trans
              i18nKey="ADMIN.CUSTOMER_BY_ID.TITLE"
              values={{
                name: capitalizeFullName(
                  (customer?.name || ' ') + ' ' + (customer?.surname || ' '),
                  t
                ),
              }}
            />
          </Typography>
        </Stack>

        {hasRoles(userAuth.user, [UserRoles.SUPER]) &&
          (isMobile ? (
            <CustomIconButton
              onClick={() => setDeleteAllDialogOpen(true)}
              style={{
                backgroundColor: 'error.main',
                color: 'common.white',
                borderColor: 'error.main',
                '&:hover': {
                  backgroundColor: lighten(theme.palette.error.main, 0.1),
                  borderColor: lighten(theme.palette.error.main, 0.1),
                },
                '&.Mui-disabled': {
                  backgroundColor: theme.palette.primary.neutral200,
                  borderColor: theme.palette.primary.neutral200,
                  color: theme.palette.primary.neutral600,
                },
              }}
              disabled={!totalScans}
            >
              <BiTrash size={18} />
            </CustomIconButton>
          ) : (
            <StyledButton
              onClick={() => setDeleteAllDialogOpen(true)}
              sx={{
                backgroundColor: 'error.main',
                color: 'common.white',
                '&:hover': {
                  backgroundColor: lighten(theme.palette.error.main, 0.1),
                },
                '&.Mui-disabled': {
                  backgroundColor: theme.palette.primary.neutral200,
                  color: theme.palette.primary.neutral600,
                },
                alignSelf: 'flex-start',
                fontSize: '14px',
              }}
              disabled={!totalScans}
            >
              {t('ADMIN.CUSTOMER_BY_ID.DELETE_ALL_SCANS')}
            </StyledButton>
          ))}
      </Stack>

      <Stack
        sx={{
          borderRadius: theme.shape.defaultBorderRadius,
          bgcolor: 'white',
          border: '1px solid',
          borderColor: 'primary.neutral200',
          p: 2,
          flex: 3,
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          gap: 3,
        }}
      >
        <Stack gap={1.5} sx={{ width: '100%' }}>
          <Typography
            variant="body1"
            sx={{
              color: 'primary.main',
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            {t('ADMIN.CUSTOMER_BY_ID.INFO')}
          </Typography>

          <Stack
            direction={'row'}
            gap={1}
            alignItems={'center'}
            sx={{
              width: { xs: '70%', sm: '30%' },
              overflow: 'hidden',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: 'primary.neutral600',
                fontSize: '14px',
                fontWeight: '500',
              }}
            >
              {t('ADMIN.CUSTOMER_BY_ID.LESSONS_LEFT')}
            </Typography>
            <Chip label={customer?.lessonsLeft || 0} size="medium" />
          </Stack>
        </Stack>

        <Stack
          sx={{
            gap: 1.5,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: 'primary.main',
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            {t('ADMIN.CUSTOMER_BY_ID.TABLE.TITLE')}
          </Typography>
          <Box
            sx={{
              width: { xs: '100%', sm: '70%' },
              '& .MuiPaper-root': {
                width: '100%',
                display: 'table',
                tableLayout: 'fixed',
              },
            }}
          >
            <MUIDataTable data={data} columns={columns} options={options} />
          </Box>
          <Stack direction={'row'} gap={1} sx={{ alignSelf: 'center' }}>
            <CustomIconButton
              disabled={page === 1}
              onClick={() => setPage((p) => p - 1)}
            >
              <BiChevronLeft size={18} />
            </CustomIconButton>
            <CustomIconButton
              disabled={!data.length || page >= totalPages}
              onClick={handleNextPage}
            >
              <BiChevronRight size={18} />
            </CustomIconButton>
          </Stack>
        </Stack>
      </Stack>

      <DeleteScanDialog
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={handleDeleteConfirm}
        loading={deleteScanState.loading}
        deleteAllLoading={deleteAllScansState.loading}
        t={t}
      />
      <DeleteScanDialog
        open={deleteAllDialogOpen}
        handleClose={() => setDeleteAllDialogOpen(false)}
        handleConfirm={handleDeleteAllScans}
        loading={deleteScanState.loading}
        deleteAllLoading={deleteAllScansState.loading}
        t={t}
        isDeleteAll
      />
    </Stack>
  );
};

const DeleteScanDialog = ({
  open,
  handleClose,
  handleConfirm,
  loading,
  deleteAllLoading,
  t,
  isDeleteAll = false,
}) => {
  const isLoading = isDeleteAll ? deleteAllLoading : loading;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-scan-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '500px',
        },
      }}
    >
      <Stack
        variant="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          px: 3,
          py: 2,
        }}
      >
        <Typography
          id="delete-scan-dialog-title"
          variant="h3"
          sx={{
            color: 'primary.neutral800',
            fontSize: '24px',
            fontWeight: '600',
            my: 1,
          }}
        >
          {isDeleteAll
            ? t('ADMIN.CUSTOMER_BY_ID.DELETE_ALL_SCANS_MODAL.TITLE')
            : t('ADMIN.CUSTOMER_BY_ID.DELETE_SCAN_MODAL.TITLE')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'primary.neutral600',
            fontSize: '16px',
            fontWeight: '400',
          }}
        >
          {isDeleteAll
            ? t('ADMIN.CUSTOMER_BY_ID.DELETE_ALL_SCANS_MODAL.DESCRIPTION')
            : t('ADMIN.CUSTOMER_BY_ID.DELETE_SCAN_MODAL.DESCRIPTION')}
        </Typography>
        <DialogActions sx={{ display: 'flex', gap: 1, p: 0 }}>
          <StyledButton
            onClick={handleClose}
            disabled={loading}
            sx={{
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'primary.neutral200',
              color: 'primary.neutral600',
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.neutral200, 0.2),
                borderColor: theme.palette.primary.neutral200,
              },
              '&.Mui-disabled': {
                color: 'primary.neutral600',
                backgroundColor: 'primary.neutral100',
                borderColor: 'primary.neutral200',
              },
            }}
          >
            {t('COMMON.CANCEL')}
          </StyledButton>
          <StyledButton
            onClick={handleConfirm}
            disabled={loading}
            sx={{
              backgroundColor: 'error.main',
              color: 'common.white',
              gap: 1,
              '&:hover': {
                backgroundColor: lighten(theme.palette.error.main, 0.1),
              },
              '&.Mui-disabled': {
                color: 'common.white',
                backgroundColor: darken(theme.palette.error.main, 0.2),
              },
            }}
          >
            {t('COMMON.DELETE')}
            {isLoading && <CircularProgress size={16} color="inherit" />}
          </StyledButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

function convertCustomerScanToData(user, scan, t, onDelete) {
  return {
    date: (
      <Typography
        sx={{
          color: 'primary.neutral800',
          fontSize: '14px',
        }}
      >
        {scan.date}
      </Typography>
    ),
    time: (
      <Typography
        sx={{
          color: 'primary.neutral800',
          fontSize: '14px',
          fontWeight: '500',
        }}
      >
        {scan.time}
      </Typography>
    ),
    actions: (
      <Stack direction="row" gap={1}>
        {hasRoles(user, [UserRoles.SUPER]) && (
          <Tooltip
            title={t('ADMIN.CUSTOMER_BY_ID.TABLE.DELETE_SCAN')}
            placement="bottom"
            arrow
          >
            <div>
              <CustomIconButton onClick={() => onDelete(scan)}>
                <BiTrash size={18} color={theme.palette.error.main} />
              </CustomIconButton>
            </div>
          </Tooltip>
        )}
      </Stack>
    ),
  };
}

function convertCustomerScanToSkeleton() {
  return {
    date: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    time: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    actions: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
  };
}
