import { forwardRef, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Link,
  Slide,
  Stack,
  Typography,
  alpha,
  lighten,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { createCollaborator } from '../../../actions/userActions';
import { v4 as uuidv4 } from 'uuid';
import {
  Article,
  CheckRounded,
  Close,
  ErrorOutlineRounded,
  ImageOutlined,
} from '@mui/icons-material';
import { Form, Formik } from 'formik';

import {
  StyledButton,
  getTransitionStyle,
} from '../../../components/Utils/UIUtils';

import { CustomInput } from '../../../components/Forms/CustomInput';
import { CustomAutocomplete } from '../../../components/Forms/CustomAutocomplete';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { storage } from '../../../config/firebase';
import { CustomTextArea } from '../../../components/Forms/CustomTextArea';
import { addCollabSchema } from '../../../schemas';
import {
  collabsCourses,
  defaultCustomersCount,
} from '../../../constants/utilsConstants';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddCollabDialog({
  addDialogOpen,
  handleAddDialogClose,
  theme,
  dispatch,
  t,
}) {
  const buttonTransition = getTransitionStyle(theme, [
    'border-color',
    'background-color',
  ]);

  const createCollab = useSelector((state) => state.createCollab);
  const storageId = useMemo(
    () => uuidv4(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createCollab.success]
  );

  const onSubmit = async (values, { setSubmitting }) => {
    if (createCollab.success || createCollab.loading) {
      return;
    }

    dispatch(
      createCollaborator({
        ...values,
        storageId: storageId,
        documents: fileURLs.map((file) => {
          return { url: file.urlFirestorage, name: file.name, type: file.type };
        }),
        courses: values.courses.map((course) => course.name),
      })
    );

    setSubmitting(false);
  };

  const [fileURLs, setFileURLs] = useState([]);
  const [fileUploadProgress, setFileUploadProgress] = useState([]);
  const [uploadTasks, setUploadTasks] = useState([]);
  const [formError, setFormError] = useState([]);

  const [invalideFileType, setInvalidFileType] = useState(false);

  const handleImageInput = () => {
    document.getElementById('image-input').value = '';
  };

  function handleImageChange(event) {
    const selectedFiles = event.target.files;
    const newFileURLs = Array.from(selectedFiles).map((file) =>
      URL.createObjectURL(file)
    );
    setFileURLs((prev) => [
      ...prev,
      ...newFileURLs.map((url, index) => ({
        url,
        name: selectedFiles[index].name,
        type: selectedFiles[index].type,
      })),
    ]);
    Array.from(selectedFiles).forEach((file) => handleUpload(file));
  }

  const user = useSelector((state) => state.userLogin?.userInfo);

  async function handleUpload(file) {
    const allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!allowedMimeTypes.includes(file.type)) {
      setInvalidFileType(true);
      setFormError((prev) => [
        ...prev,
        t('ADMIN.COLLABS.ADD_COLLAB_MODAL.INVALID_FILE_TYPE'),
      ]);
      return;
    }

    if (user === null) {
      setFormError((prev) => [
        ...prev,
        t('ADMIN.COLLABS.ADD_COLLAB_MODAL.LOGIN_REQUIRED'),
      ]);
      return;
    }

    if (!file) {
      setFormError((prev) => [
        ...prev,
        t('ADMIN.COLLABS.ADD_COLLAB_MODAL.NO_FILE_SELECTED'),
      ]);
      return;
    }

    const fileName = file.name.split('.').slice(0, -1).join('.');

    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}-${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    const fileExtension = file.name.split('.').pop();

    const storageRef = ref(
      storage,
      `/collabs-docs/${storageId}/${fileName}-${formattedDate}.${fileExtension}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploadTasks((prev) => [...prev, uploadTask]);

    try {
      setFileUploadProgress((prev) => [
        ...prev,
        { name: file.name, progress: 0 },
      ]);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setFileUploadProgress((prev) =>
            prev.map((item) =>
              item.name === file.name ? { ...item, progress } : item
            )
          );
        },
        (error) => {
          if (error.code === 'storage/canceled') return;

          setFormError((prev) => [
            ...prev,
            t('ADMIN.COLLABS.ADD_COLLAB_MODAL.UPLOAD_ERROR'),
          ]);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFileURLs((prev) =>
              prev.map((item) =>
                item.name === file.name
                  ? { ...item, urlFirestorage: url }
                  : item
              )
            );
          });
        }
      );
    } catch (error) {
      setFormError((prev) => [
        ...prev,
        t('ADMIN.COLLABS.ADD_COLLAB_MODAL.UPLOAD_ERROR'),
      ]);
    }
  }

  const handleClearImage = async (index) => {
    try {
      if (uploadTasks[index]) {
        uploadTasks[index].cancel();
      }

      const fileToDelete = fileURLs[index];

      const fileRef = ref(storage, fileToDelete?.urlFirestorage);

      if (fileToDelete?.urlFirestorage) await deleteObject(fileRef);

      setFileURLs((prev) => prev.filter((_, i) => i !== index));
      setFileUploadProgress((prev) => prev.filter((_, i) => i !== index));
      setUploadTasks((prev) => prev.filter((_, i) => i !== index));
      setInvalidFileType(false);
      setFormError([]);
    } catch (error) {
      setFormError((prev) => [
        ...prev,
        t('ADMIN.COLLABS.ADD_COLLAB_MODAL.DELETE_ERROR'),
      ]);
    }
  };

  const formikRef = useRef();

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      open={addDialogOpen}
      onClose={handleAddDialogClose}
      aria-labelledby="add-customer-dialog-title"
    >
      <DialogContent sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
        <Formik
          initialValues={{
            name: '',
            surname: '',
            dateOfBirth: '',
            address: '',
            city: '',
            taxId: '',
            zipcode: '',
            courses: [{ name: 'Pilates', id: 1 }],
            customersCount: defaultCustomersCount,
            email: '',
            documents: '',
            notes: '',
            phoneNumber: '',
          }}
          validationSchema={() => addCollabSchema(t)}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {() => {
            return (
              <Stack gap={2} justifyContent={'center'}>
                <Stack
                  direction={'row'}
                  gap={2}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      color: 'primary.neutral800',
                      fontWeight: '500',
                      fontSize: '20px',
                    }}
                  >
                    {t('ADMIN.COLLABS.ADD_COLLAB_MODAL.TITLE')}
                  </Typography>
                  <IconButton
                    onClick={handleAddDialogClose}
                    sx={{
                      p: 0,
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <Close />
                  </IconButton>
                </Stack>
                {!createCollab.success && (
                  <Form
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                    id="add-collab-form"
                    name="add-collab-form"
                  >
                    <Stack
                      gap={{
                        xs: 0,
                        md: 2,
                      }}
                      direction={{ xs: 'column', md: 'row' }}
                    >
                      <Stack gap={2} direction={'row'} sx={{ width: '100%' }}>
                        <CustomInput
                          label={t('ADMIN.COLLABS.ADD_COLLAB_MODAL.NAME')}
                          name="name"
                          id="name"
                          type="text"
                          autoComplete="off"
                          placeholder={t(
                            'ADMIN.COLLABS.ADD_COLLAB_MODAL.NAME_PLACEHOLDER'
                          )}
                          manualMarginBottom={'16px'}
                        />
                        <CustomInput
                          label={t('ADMIN.COLLABS.ADD_COLLAB_MODAL.SURNAME')}
                          name="surname"
                          id="surname"
                          type="text"
                          autoComplete="off"
                          placeholder={t(
                            'ADMIN.COLLABS.ADD_COLLAB_MODAL.SURNAME_PLACEHOLDER'
                          )}
                          manualMarginBottom={'16px'}
                        />
                      </Stack>

                      <Stack gap={2} direction={'row'} sx={{ width: '100%' }}>
                        <CustomInput
                          label={t(
                            'ADMIN.COLLABS.ADD_COLLAB_MODAL.DATE_OF_BIRTH'
                          )}
                          name="dateOfBirth"
                          id="dateOfBirth"
                          type="date"
                          manualMarginBottom={'16px'}
                        />
                        <CustomInput
                          label={t('ADMIN.COLLABS.ADD_COLLAB_MODAL.TAX_ID')}
                          name="taxId"
                          id="taxId"
                          type="text"
                          autoComplete="off"
                          placeholder={t(
                            'ADMIN.COLLABS.ADD_COLLAB_MODAL.TAX_ID_PLACEHOLDER'
                          )}
                          manualMarginBottom={'16px'}
                        />
                      </Stack>
                    </Stack>

                    <Stack gap={2} direction={'row'}>
                      <CustomInput
                        label={t('ADMIN.COLLABS.ADD_COLLAB_MODAL.ADDRESS')}
                        name="address"
                        id="address"
                        type="text"
                        autoComplete="off"
                        placeholder={t(
                          'ADMIN.COLLABS.ADD_COLLAB_MODAL.ADDRESS'
                        )}
                        manualMarginBottom={'16px'}
                      />
                      <CustomInput
                        label={t('ADMIN.COLLABS.ADD_COLLAB_MODAL.ZIP_CODE')}
                        name="zipcode"
                        id="zipcode"
                        type="text"
                        autoComplete="off"
                        placeholder={t(
                          'ADMIN.COLLABS.ADD_COLLAB_MODAL.ZIP_CODE'
                        )}
                        manualMarginBottom={'16px'}
                      />
                    </Stack>

                    <Stack
                      gap={{ xs: 0, md: 2 }}
                      direction={{ xs: 'column', md: 'row' }}
                    >
                      <Stack gap={2} direction={'row'} width={'100%'}>
                        <CustomInput
                          label={t('ADMIN.COLLABS.ADD_COLLAB_MODAL.CITY')}
                          name="city"
                          id="city"
                          type="text"
                          autoComplete="off"
                          placeholder={t(
                            'ADMIN.COLLABS.ADD_COLLAB_MODAL.CITY_PLACEHOLDER'
                          )}
                          manualMarginBottom={'16px'}
                        />
                        <CustomInput
                          label={t(
                            'ADMIN.COLLABS.ADD_COLLAB_MODAL.PHONE_NUMBER'
                          )}
                          name="phoneNumber"
                          id="phoneNumber"
                          type="text"
                          autoComplete="off"
                          placeholder={t(
                            'ADMIN.COLLABS.ADD_COLLAB_MODAL.PHONE_NUMBER_PLACEHOLDER'
                          )}
                          manualMarginBottom={'16px'}
                        />
                      </Stack>

                      <CustomAutocomplete
                        label={t('ADMIN.COLLABS.ADD_COLLAB_MODAL.COURSES')}
                        name={`courses`}
                        id={`courses`}
                        options={collabsCourses.map((course) => course)}
                        placeholder={t(
                          'ADMIN.COLLABS.ADD_COLLAB_MODAL.COURSES_PLACEHOLDER'
                        )}
                        disableCloseOnSelect
                        manualMarginBottom={'16px'}
                      />
                    </Stack>

                    <Stack
                      gap={{ xs: 0, md: 2 }}
                      direction={{ xs: 'column', md: 'row' }}
                    >
                      <CustomInput
                        label={t(
                          'ADMIN.COLLABS.ADD_COLLAB_MODAL.CUSTOMERS_COUNT'
                        )}
                        name="customersCount"
                        id="customersCount"
                        type="text"
                        autoComplete="off"
                        placeholder={t(
                          'ADMIN.COLLABS.ADD_COLLAB_MODAL.CUSTOMERS_COUNT_PLACEHOLDER'
                        )}
                        manualMarginBottom={'16px'}
                      />
                      <CustomInput
                        label={t('ADMIN.COLLABS.ADD_COLLAB_MODAL.EMAIL')}
                        name="email"
                        id="email"
                        type="email"
                        autoComplete="off"
                        placeholder={t(
                          'ADMIN.COLLABS.ADD_COLLAB_MODAL.EMAIL_PLACEHOLDER'
                        )}
                        manualMarginBottom={'16px'}
                      />
                    </Stack>

                    <CustomTextArea
                      label={t('ADMIN.COLLABS.ADD_COLLAB_MODAL.NOTES')}
                      name="notes"
                      id="notes"
                      autoComplete="off"
                      placeholder={t(
                        'ADMIN.COLLABS.ADD_COLLAB_MODAL.NOTES_PLACEHOLDER'
                      )}
                      manualMarginBottom={'16px'}
                      multiline
                      rows={2}
                    />

                    <Stack gap={1} sx={{ marginBottom: '16px' }}>
                      <Typography
                        component={'label'}
                        sx={{ fontSize: '16px', fontWeight: '500' }}
                      >
                        {t('ADMIN.COLLABS.ADD_COLLAB_MODAL.DOCUMENTS')}
                      </Typography>
                      <Stack gap={2}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            width: '100%',
                            border: '2px dashed',
                            borderColor: 'primary.primary500',
                            gap: '8px',
                            borderRadius: theme.shape.defaultBorderRadius,
                            p: '10px',
                          }}
                        >
                          <ImageOutlined sx={{ color: 'primary.main' }} />
                          <Typography
                            variant="h3"
                            sx={{
                              fontSize: '16px',
                              color: 'text.secondary',
                              textAlign: 'center',
                            }}
                          >
                            {t('ADMIN.COLLABS.ADD_COLLAB_MODAL.DRAG_DROP')}
                          </Typography>
                          <Link
                            component="label"
                            onClick={handleImageInput}
                            sx={{
                              cursor: 'pointer',
                              textDecoration: 'none',
                              fontWeight: '500',
                            }}
                          >
                            {t('ADMIN.COLLABS.ADD_COLLAB_MODAL.BROWSE')}
                            <input
                              id="image-input"
                              name="image"
                              type="file"
                              accept="image/jpeg, image/png, application/pdf"
                              multiple
                              style={{ display: 'none' }}
                              onChange={handleImageChange}
                            />
                          </Link>
                        </Box>

                        {formError.length > 0 && (
                          <Alert
                            severity="error"
                            sx={{
                              borderRadius: theme.shape.defaultBorderRadius,
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="text.error"
                              component="div"
                            >
                              {formError.map((error, index) => (
                                <div key={index}>{error}</div>
                              ))}
                            </Typography>
                          </Alert>
                        )}

                        <Stack
                          direction="row"
                          flexWrap="wrap"
                          gap={2}
                          justifyContent="flex-start"
                        >
                          {fileURLs.map((file, index) => (
                            <Stack
                              key={index}
                              direction={'row'}
                              gap={1}
                              alignItems={'center'}
                            >
                              <Box
                                sx={{ height: '100%', position: 'relative' }}
                              >
                                {file.type === 'application/pdf' ? (
                                  <Box
                                    sx={{
                                      width: '64px',
                                      height: '64px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      border: '1px solid',
                                      borderColor: 'primary.neutral300',
                                      borderRadius:
                                        theme.shape.chipBorderRadius,
                                    }}
                                  >
                                    <Article
                                      sx={{
                                        fontSize: '32px',
                                        color: 'primary.neutral500',
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <img
                                    src={file.url}
                                    alt={`file-preview-${index}`}
                                    style={{
                                      width: '64px',
                                      height: '64px',
                                      objectFit: 'cover',
                                      borderRadius:
                                        theme.shape.chipBorderRadius,
                                    }}
                                  />
                                )}
                                <IconButton
                                  onClick={() => handleClearImage(index)}
                                  sx={{
                                    position: 'absolute',
                                    top: 4,
                                    right: 4,
                                    width: '18px',
                                    height: '18px',
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                    color: 'white',
                                    '&:hover': {
                                      backgroundColor: 'rgba(0,0,0,0.7)',
                                    },
                                  }}
                                >
                                  <Close sx={{ fontSize: '16px' }} />
                                </IconButton>
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    backgroundColor: 'primary.main',
                                    borderRadius: `0 0 ${theme.shape.chipBorderRadius} ${theme.shape.chipBorderRadius}`,
                                    color: 'white',
                                    fontSize: '12px',
                                    textAlign: 'center',
                                  }}
                                >
                                  {fileUploadProgress.find(
                                    (item) => item.name === file.name
                                  )?.progress || 0}
                                  %
                                </Box>
                              </Box>
                              <Typography
                                variant="body2"
                                color="primary.neutral800"
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '500',
                                }}
                              >
                                {file.name}
                              </Typography>
                            </Stack>
                          ))}
                        </Stack>
                      </Stack>
                    </Stack>

                    <DialogActions sx={{ display: 'flex', gap: 1, p: 0 }}>
                      <StyledButton
                        onClick={handleAddDialogClose}
                        sx={{
                          backgroundColor: 'transparent',
                          border: '1px solid',
                          borderColor: 'primary.neutral200',
                          color: 'primary.neutral600',
                          ...buttonTransition,
                          '&:hover': {
                            backgroundColor: alpha(
                              theme.palette.primary.neutral200,
                              0.2
                            ),
                            borderColor: theme.palette.primary.neutral200,
                          },
                        }}
                      >
                        {t('ADMIN.COLLABS.ADD_COLLAB_MODAL.CLOSE')}
                      </StyledButton>
                      <StyledButton
                        autoFocus
                        sx={{
                          backgroundColor: 'primary.main',
                          color: 'primary.white',
                          '&:hover': {
                            backgroundColor: lighten(
                              theme.palette.primary.main,
                              0.1
                            ),
                          },
                          '&:disabled': {
                            backgroundColor: theme.palette.primary.neutral200,
                            color: theme.palette.primary.neutral600,
                          },
                        }}
                        type="submit"
                        onSubmit={onSubmit}
                        disabled={
                          createCollab.success ||
                          createCollab.loading ||
                          invalideFileType ||
                          fileUploadProgress.some((item) => item.progress < 100)
                        }
                      >
                        {t('ADMIN.COLLABS.ADD_COLLAB_MODAL.ADD_COLLAB')}
                        {createCollab.loading && (
                          <CircularProgress color="inherit" size={'16px'} />
                        )}
                      </StyledButton>
                    </DialogActions>
                  </Form>
                )}
              </Stack>
            );
          }}
        </Formik>
        {createCollab.error && (
          <Alert severity={'error'} icon={false}>
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <ErrorOutlineRounded
                sx={{
                  fontSize: '28px',
                  color: theme.palette.error.main,
                  backgroundColor: alpha(theme.palette.error.main, 0.1),
                  borderRadius: '50%',
                  p: 0.5,
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px',
                  textAlign: 'left',
                  color: 'primary.neutral600',
                }}
              >
                {createCollab.error}
              </Typography>
            </Stack>
          </Alert>
        )}
        {createCollab.success && (
          <Alert
            severity={'success'}
            icon={false}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              backgroundColor: 'transparent',
            }}
          >
            <Stack alignItems={'center'} gap={2}>
              <Stack direction={'row'} gap={2} alignItems={'center'}>
                <CheckRounded
                  sx={{
                    fontSize: '64px',
                    color: theme.palette.success.main,
                    backgroundColor: alpha(theme.palette.success.main, 0.1),
                    borderRadius: '50%',
                    p: 0.5,
                  }}
                />
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    height: '32px',
                    my: 'auto',
                  }}
                />
                <img
                  src={createCollab.collab?.qrCode}
                  alt="qr-code"
                  style={{ width: '128px', height: '128px' }}
                />
              </Stack>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px',
                  textAlign: 'left',
                  color: 'primary.neutral600',
                }}
              >
                {t('ADMIN.COLLABS.ADD_COLLAB_MODAL.SUCCESS')}
              </Typography>
              <Stack direction={'row'} gap={1}>
                <StyledButton
                  onClick={handleAddDialogClose}
                  sx={{
                    backgroundColor: 'transparent',
                    border: '1px solid',
                    borderColor: 'primary.neutral200',
                    color: 'primary.neutral600',
                    ...buttonTransition,
                    '&:hover': {
                      backgroundColor: alpha(
                        theme.palette.primary.neutral200,
                        0.2
                      ),
                      borderColor: theme.palette.primary.neutral200,
                    },
                  }}
                >
                  {t('ADMIN.COLLABS.ADD_COLLAB_MODAL.CLOSE')}
                </StyledButton>
                <StyledButton
                  autoFocus
                  onClick={() => {
                    dispatch({ type: 'COLLAB_CREATE_RESET' });
                    setFileURLs([]);
                    formikRef?.current?.resetForm();
                  }}
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.white',
                    '&:hover': {
                      backgroundColor: lighten(theme.palette.primary.main, 0.1),
                    },
                    '&:disabled': {
                      backgroundColor: theme.palette.primary.neutral200,
                      color: theme.palette.primary.neutral600,
                    },
                  }}
                >
                  {t('ADMIN.COLLABS.ADD_COLLAB_MODAL.CREATE_ANOTHER')}
                </StyledButton>
              </Stack>
            </Stack>
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AddCollabDialog;
