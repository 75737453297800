import { useState, useEffect } from 'react';
import { Typography, Stack } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import { CustomIconButton } from './UIUtils';
import { capitalizeFirstLetter } from './Formating';

const calculateMonthlyTotals = (scans = {}, i18n, t) => {
  const monthlyTotals = {};

  Object.entries(scans).forEach(([monthKey, monthScans]) => {
    if (!Array.isArray(monthScans)) {
      monthlyTotals[monthKey] = monthScans.count || 0;
    } else {
      monthlyTotals[monthKey] = monthScans.length;
    }
  });

  return Object.entries(monthlyTotals)
    .map(([monthKey, total]) => {
      const [month, year] = monthKey.split('-');
      const date = new Date(parseInt(year), parseInt(month) - 1, 1);
      const monthYear = date.toLocaleDateString(i18n.language, {
        year: 'numeric',
        month: 'long',
      });

      return {
        monthKey,
        date,
        total,
        monthYear: capitalizeFirstLetter(monthYear, t),
      };
    })
    .sort((a, b) => b.date - a.date);
};

const MonthlyTotalsTable = ({ scans, t, i18n }) => {
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [paginatedData, setPaginatedData] = useState([]);
  const allMonthlyData = calculateMonthlyTotals(scans, i18n, t);
  const totalCount = allMonthlyData.length;

  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const currentPageData = allMonthlyData
      .slice(startIndex, endIndex)
      .map((item) => ({
        month: (
          <Typography sx={{ color: 'primary.neutral800', fontSize: '14px' }}>
            {item.monthYear}
          </Typography>
        ),
        total: (
          <Typography
            sx={{
              color: 'primary.neutral800',
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            {item.total}
          </Typography>
        ),
      }));

    setPaginatedData(currentPageData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, scans, i18n]);

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalCount / pageSize);
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const columns = [
    {
      name: 'month',
      label: t('MONTHLY_TOTALS.TABLE.MONTH'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'total',
      label: t('MONTHLY_TOTALS.TABLE.TOTAL_CUSTOMERS'),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    download: false,
    elevation: 0,
    print: false,
    filter: false,
    search: false,
    selectableRows: 'none',
    viewColumns: false,
    rowsPerPageOptions: [],
    responsive: 'standard',
    customFooter: () => null,
    textLabels: {
      body: {
        noMatch: t('MONTHLY_TOTALS.TABLE.NO_MATCH'),
      },
    },
  };

  return (
    <Stack gap={1.5}>
      <MUIDataTable data={paginatedData} columns={columns} options={options} />
      <Stack direction={'row'} gap={1} sx={{ alignSelf: 'center' }}>
        <CustomIconButton disabled={page === 1} onClick={handlePreviousPage}>
          <BiChevronLeft size={18} />
        </CustomIconButton>
        <CustomIconButton
          disabled={
            page === Math.ceil(totalCount / pageSize) || totalCount === 0
          }
          onClick={handleNextPage}
        >
          <BiChevronRight size={18} />
        </CustomIconButton>
      </Stack>
    </Stack>
  );
};

export default MonthlyTotalsTable;
