import { useState } from 'react';
import { Box, ButtonGroup, Stack, Typography } from '@mui/material';
import { useField } from 'formik';

import { theme } from '../../theme';
import { getTransitionStyle, StyledButton } from '../Utils/UIUtils';

export const CustomLessonsInput = ({
  manualMarginBottom = false,
  label,
  defaultLessonsCount = 10,
  lessonsLeft = 0,
  ...props
}) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const [lessonsCount, setLessonsCount] = useState(defaultLessonsCount);

  const transitionStyle = getTransitionStyle(theme, [
    'background-color',
    'border-color',
    'color',
  ]);

  const handleLessonsCount = (count) => {
    setLessonsCount(count);
    setValue(count);
  };

  const rows =
    lessonsCount === 30
      ? [
          Array.from({ length: 15 }, (_, i) => i + 1),
          Array.from({ length: 15 }, (_, i) => i + 16),
        ]
      : [Array.from({ length: lessonsCount }, (_, i) => i + 1)];

  return (
    <Stack
      direction="column"
      sx={{
        width: '100%',
        ...(manualMarginBottom && {
          marginBottom: manualMarginBottom,
        }),
      }}
    >
      <Stack gap={1}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          {label && (
            <Typography
              color="primary.neutral800"
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                '& +.MuiInputBase-root': {
                  marginTop: 0,
                },
              }}
            >
              {label}
            </Typography>
          )}
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            sx={{
              '& .MuiButtonBase-root': {
                width: '24px',
                height: '36px',
                color: 'primary.neutral800',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                },
                '&.selected': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                },
                ...transitionStyle,
              },
            }}
          >
            <StyledButton
              onClick={() => handleLessonsCount(10)}
              className={lessonsCount === 10 ? 'selected' : ''}
            >
              10
            </StyledButton>
            <StyledButton
              onClick={() => handleLessonsCount(15)}
              className={lessonsCount === 15 ? 'selected' : ''}
            >
              15
            </StyledButton>
            <StyledButton
              onClick={() => handleLessonsCount(20)}
              className={lessonsCount === 20 ? 'selected' : ''}
            >
              20
            </StyledButton>
            <StyledButton
              onClick={() => handleLessonsCount(30)}
              className={lessonsCount === 30 ? 'selected' : ''}
            >
              30
            </StyledButton>
          </ButtonGroup>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          {rows.map((row, rowIndex) => (
            <Stack
              key={rowIndex}
              direction="row"
              gap={1}
              sx={{
                flexWrap: 'wrap',
                '& > div': {
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid',
                  borderColor: 'primary.neutral300',
                  borderRadius: theme.shape.defaultBorderRadius,
                  '&.paid': {
                    backgroundColor: 'primary.main',
                    color: 'white',
                    borderColor: 'primary.main',
                  },
                  ...transitionStyle,
                },
              }}
            >
              {row.map((item, i) => (
                <div
                  key={item}
                  className={
                    (rowIndex === 0 && i < lessonsCount - lessonsLeft) ||
                    (rowIndex === 1 && i + 15 < lessonsCount - lessonsLeft)
                      ? 'paid'
                      : ''
                  }
                >
                  {item}
                </div>
              ))}
            </Stack>
          ))}
        </Box>
      </Stack>

      {meta.error ? (
        <Typography sx={{ fontSize: '14px', mt: 0.5, color: 'error.main' }}>
          {meta.error}
        </Typography>
      ) : null}
    </Stack>
  );
};
