import { Routes as WebRoutes, Route, Navigate } from 'react-router';
import { useSelector } from 'react-redux';

import AdminRoute from './auth/AdminRoute';

import LightThemeLayout from './layouts/LightThemeLayout';
import AdminLayout from './layouts/AdminLayout';
import AuthLayout from './layouts/AuthLayout';

import NotFound from './pages/NotFound/NotFound';
import Login from './pages/Login/Login';
import Customers from './pages/Admin/Customers/Customers';
import Collabs from './pages/Admin/Collabs/Collabs';
import SuperAdminRoute from './auth/SuperAdminRoute';
import CollabRoute from './auth/CollabRoute';
import Collab from './pages/Collab/Collab';
import CollabById from './pages/Collab/CollabById';
import QRCodeScanner from './pages/QRCodeScanner/QRCodeScanner';
import { CustomerById } from './pages/Admin/Customers/CustomerById';

export default function Routes() {
  const user = useSelector((state) => state.userLogin);
  return (
    <WebRoutes>
      {/* LightTheme Layout */}
      <Route element={<LightThemeLayout />}>
        <Route path="*" Component={NotFound}></Route>
      </Route>

      {/* Auth Layout */}
      <Route element={user.userInfo ? <Navigate to={'/'} /> : <AuthLayout />}>
        <Route path="/login" Component={Login}></Route>
      </Route>

      {/* Protected Route */}
      <Route
        element={
          <AdminRoute>
            <LightThemeLayout />
          </AdminRoute>
        }
      >
        <Route path="/scan" Component={QRCodeScanner}></Route>
      </Route>

      {/* Admin Layout */}
      <Route
        element={
          <AdminRoute>
            <AdminLayout />
          </AdminRoute>
        }
      >
        <Route path="/" element={<Customers />}></Route>
        <Route path="/:id" element={<CustomerById />} />
      </Route>

      {/* SuperAdmin Layout */}
      <Route
        element={
          <SuperAdminRoute>
            <AdminLayout />
          </SuperAdminRoute>
        }
      >
        <Route path="/collabs" element={<Collabs />}></Route>
      </Route>

      {/* Collab Layout */}
      <Route
        element={
          <CollabRoute>
            <AdminLayout />
          </CollabRoute>
        }
      >
        <Route path="/collab" element={<Collab />}></Route>
        <Route path="/collabs/:id" element={<CollabById />}></Route>
      </Route>
    </WebRoutes>
  );
}
