import { forwardRef, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  Typography,
  alpha,
  lighten,
} from '@mui/material';
import { BiDownArrowAlt } from 'react-icons/bi';

import {
  StyledButton,
  getTransitionStyle,
} from '../../../components/Utils/UIUtils';
import {
  capitalizeFirstLetter,
  capitalizeFullName,
  formatDate,
} from '../../../components/Utils/Formating';
import { Article, Close } from '@mui/icons-material';
import { Timestamp } from 'firebase/firestore';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ViewCollabDialog({
  viewDialogOpen,
  handleViewDialogClose,
  collab,
  theme,
  t,
}) {
  const buttonTransition = getTransitionStyle(theme, [
    'border-color',
    'background-color',
  ]);

  const transitionStyle = getTransitionStyle(theme, [
    'background-color',
    'border-color',
    'color',
  ]);

  const [fileURLs, setFileURLs] = useState([]);

  useEffect(() => {
    if (collab) {
      const files = collab.documents;
      setFileURLs(files);
    }
  }, [collab]);

  const today = Timestamp.now();
  const dateKey = `${today.toDate().getMonth() + 1}-${today
    .toDate()
    .getFullYear()}`;

  const customerThisMonth = collab?.scans[dateKey]?.count || 0;

  return (
    collab && (
      <Dialog
        TransitionComponent={Transition}
        open={viewDialogOpen}
        onClose={handleViewDialogClose}
        aria-labelledby="view-collab-dialog-title"
        sx={{
          '& .MuiDialog-paper': {
            width: '800px',
          },
        }}
      >
        <Stack
          variant="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            px: 3,
            py: 2,
            '& .MuiChip-root': {
              backgroundColor: theme.palette.primary.neutral200,
              fontWeight: '500',
              borderRadius: theme.shape.secondaryBorderRadius,
              height: 'fit-content',
              py: 0.8,
            },
            '& .MuiChip-label': {
              color: 'primary.neutral700',
              whiteSpace: 'normal',
            },
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
              width: '100%',
            }}
          >
            <Typography
              id="view-send-dialog-title"
              variant="h3"
              sx={{
                color: 'primary.neutral800',
                fontSize: '24px',
                fontWeight: '600',
                p: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {capitalizeFullName(collab.name + ' ' + collab.surname, t)}
            </Typography>
            <IconButton
              onClick={handleViewDialogClose}
              sx={{
                p: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Close />
            </IconButton>
          </Stack>

          <Stack gap={2}>
            <Typography
              variant="body1"
              sx={{
                color: 'primary.main',
                fontSize: '14px',
                fontWeight: '600',
                mt: 1,
              }}
            >
              {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.USER_INFO')}
            </Typography>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.ADDRESS')}
              </Typography>

              <Chip
                label={
                  collab.address + ', ' + collab.city + ', ' + collab.zipcode
                }
                size="medium"
              />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.CITY')}
              </Typography>

              <Chip label={collab.city} size="medium" />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ width: '100%', overflow: 'hidden', flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.DATE_OF_BIRTH')}
              </Typography>
              <Chip
                label={formatDate(new Date(collab.dateOfBirth), t)}
                size="medium"
              />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ width: '100%', overflow: 'hidden', flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.PHONE_NUMBER')}
              </Typography>
              <Chip label={collab.phoneNumber} size="medium" />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ width: '100%', overflow: 'hidden', flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.TAX_ID')}
              </Typography>
              <Chip label={collab.taxId} size="medium" />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ width: '100%', overflow: 'hidden', flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.NOTES')}
              </Typography>
              <Chip
                label={
                  collab.notes
                    ? capitalizeFirstLetter(collab.notes)
                    : t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.NO_NOTES')
                }
                size="medium"
              />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ width: '100%', overflow: 'hidden', flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.COURSES')}
              </Typography>
              <Chip label={collab.courses.join(', ')} size="medium" />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ width: '100%', overflow: 'hidden', flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.CUSTOMERS_LEFT')}
              </Typography>
              <Chip label={collab.customersCount} size="medium" />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ width: '100%', overflow: 'hidden', flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.CUSTOMERS_THIS_MONTH')}
              </Typography>
              <Chip label={customerThisMonth} size="medium" />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ width: '100%', overflow: 'hidden', flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.QR_CODE')}
              </Typography>
              <StyledButton
                sx={{
                  ...transitionStyle,
                  backgroundColor: 'primary.main',
                  color: 'primary.white',
                  '&:hover': {
                    backgroundColor: lighten(theme.palette.primary.main, 0.1),
                  },
                }}
                startIcon={<BiDownArrowAlt />}
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = collab.qrCode;
                  link.download =
                    (collab.name + '_' + collab.surname).toLowerCase() +
                    '_qr_code.png';
                  link.click();
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.DOWNLOAD_QR_CODE')}
              </StyledButton>
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ width: '100%', overflow: 'hidden', flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.DOCUMENTS')}
              </Typography>

              {fileURLs.length === 0 && (
                <Typography
                  variant="body2"
                  sx={{
                    color: 'primary.main',
                    fontSize: '14px',
                    fontWeight: '500',
                    minWidth: '35%',
                  }}
                >
                  {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.NO_DOCUMENTS')}
                </Typography>
              )}

              <Stack
                direction="row"
                flexWrap="wrap"
                gap={2}
                justifyContent="flex-start"
              >
                {fileURLs.map((file, index) => (
                  <Stack
                    key={index}
                    direction={'row'}
                    gap={1}
                    alignItems={'center'}
                  >
                    <Box sx={{ height: '100%', position: 'relative' }}>
                      {file.type === 'application/pdf' ? (
                        <Box
                          onClick={() => {
                            const link = document.createElement('a');
                            link.href = file.url;
                            link.download = file.name;
                            link.target = '_blank';
                            link.click();
                          }}
                          sx={{
                            cursor: 'pointer',
                            width: '48px',
                            height: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid',
                            borderColor: 'primary.neutral300',
                            borderRadius: theme.shape.chipBorderRadius,
                          }}
                        >
                          <Article
                            sx={{
                              fontSize: '32px',
                              color: 'primary.neutral500',
                            }}
                          />
                        </Box>
                      ) : (
                        <img
                          onClick={() => {
                            const link = document.createElement('a');
                            link.href = file.url;
                            link.download = file.name;
                            link.target = '_blank';
                            link.click();
                          }}
                          src={file.url}
                          alt={`file-preview-${index}`}
                          loading="lazy"
                          style={{
                            cursor: 'pointer',
                            width: '48px',
                            height: '48px',
                            objectFit: 'cover',
                            borderRadius: theme.shape.chipBorderRadius,
                          }}
                        />
                      )}
                    </Box>
                    <Tooltip title={file.name} placement="top">
                      <Typography
                        onClick={() => {
                          const link = document.createElement('a');
                          link.href = file.url;
                          link.download = file.name;
                          link.target = '_blank';
                          link.click();
                        }}
                        variant="body2"
                        color="primary.neutral800"
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          cursor: 'pointer',
                          '&:hover': {
                            color: 'primary.main',
                          },
                        }}
                      >
                        {' '}
                        {file.name.length > 10
                          ? file.name.substring(0, 5) +
                            '...' +
                            file.name.substring(file.name.length - 5)
                          : file.name}
                      </Typography>
                    </Tooltip>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={2}>
            <Typography
              variant="body1"
              sx={{
                color: 'primary.main',
                fontSize: '14px',
                fontWeight: '600',
                mt: 1,
              }}
            >
              {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.INFO')}
            </Typography>
            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.CREATED_AT')}
              </Typography>
              <Chip label={formatDate(collab.createdAt, t)} size="medium" />
            </Stack>
            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{ width: '100%', overflow: 'hidden', flexWrap: 'wrap' }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                  minWidth: '35%',
                }}
              >
                {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.ID')}
              </Typography>
              <Chip label={collab.id} size="medium" />
            </Stack>
          </Stack>
        </Stack>
        <DialogContent
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            overflowY: 'clip',
          }}
        >
          <DialogActions sx={{ display: 'flex', gap: 1, p: 0 }}>
            <StyledButton
              onClick={handleViewDialogClose}
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid',
                borderColor: 'primary.neutral200',
                color: 'primary.neutral600',
                ...buttonTransition,
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.neutral200, 0.2),
                  borderColor: theme.palette.primary.neutral200,
                },
              }}
            >
              {t('ADMIN.COLLABS.VIEW_COLLAB_MODAL.CLOSE')}
            </StyledButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  );
}

export default ViewCollabDialog;
