import { forwardRef, useEffect, useState } from 'react';
import {
  Alert,
  alpha,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  lighten,
  Link,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Article, Close, Done, ImageOutlined } from '@mui/icons-material';
import { Form, Formik } from 'formik';

import {
  getTransitionStyle,
  StyledButton,
} from '../../../components/Utils/UIUtils';

import { CustomInput } from '../../../components/Forms/CustomInput';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { storage } from '../../../config/firebase';
import {
  deleteDocumentFromDB,
  updateCollab,
} from '../../../actions/userActions';
import { CustomAutocomplete } from '../../../components/Forms/CustomAutocomplete';
import { CustomTextArea } from '../../../components/Forms/CustomTextArea';
import { capitalizeFirstLetter } from '../../../components/Utils/Formating';
import { updateCollabSchema } from '../../../schemas';
import { collabsCourses } from '../../../constants/utilsConstants';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteFileDialog({
  deleteDialogOpen,
  handleDeleteDialogClose,
  collabId,
  fileToDelete,
  fileIndexToDelete,
  handleClearImage,
  dispatch,
  theme,
  t,
}) {
  const buttonTransition = getTransitionStyle(theme, [
    'border-color',
    'background-color',
  ]);

  const collabDocDeletion = useSelector((state) => state.deleteDocument);

  useEffect(() => {
    if (collabDocDeletion.success) {
      dispatch({ type: 'DOCUMENT_DELETION_RESET' });
      handleDeleteDialogClose();
    }
  }, [collabDocDeletion.success, dispatch, handleDeleteDialogClose]);

  const handleDeleteCollab = async () => {
    const fileRef = ref(storage, fileToDelete?.url);
    dispatch(
      deleteDocumentFromDB(collabId, fileToDelete?.name, 'collaborators')
    );

    await deleteObject(fileRef);
    handleClearImage(fileIndexToDelete);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={deleteDialogOpen}
      onClose={handleDeleteDialogClose}
      aria-labelledby="delete-collab-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '500px',
        },
      }}
    >
      <Stack
        variant="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          px: 3,
          py: 2,
        }}
      >
        <Typography
          id="delete-collab-dialog-title"
          variant="h3"
          sx={{
            color: 'primary.neutral800',
            fontSize: '24px',
            fontWeight: '600',
            my: 1,
          }}
        >
          {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.DELETE_DOCUMENT_MODAL.TITLE')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'primary.neutral600',
            fontSize: '16px',
            fontWeight: '400',
          }}
        >
          {t(
            'ADMIN.COLLABS.EDIT_COLLAB_MODAL.DELETE_DOCUMENT_MODAL.DESCRIPTION'
          )}
        </Typography>
        <DialogActions sx={{ display: 'flex', gap: 1, p: 0 }}>
          <StyledButton
            onClick={handleDeleteDialogClose}
            sx={{
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'primary.neutral200',
              color: 'primary.neutral600',
              ...buttonTransition,
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.neutral200, 0.2),
                borderColor: theme.palette.primary.neutral200,
              },
            }}
          >
            {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.DELETE_DOCUMENT_MODAL.CANCEL')}
          </StyledButton>
          <StyledButton
            autoFocus
            onClick={handleDeleteCollab}
            sx={{
              backgroundColor: 'error.main',
              color: 'common.white',
              '&:hover': {
                backgroundColor: lighten(theme.palette.error.main, 0.1),
              },
            }}
          >
            {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.DELETE_DOCUMENT_MODAL.DELETE')}
          </StyledButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

function EditCollabDialog({
  editDialogOpen,
  handleEditDialogClose,
  theme,
  dispatch,
  t,
  collab,
}) {
  const buttonTransition = getTransitionStyle(theme, [
    'border-color',
    'background-color',
  ]);

  const updateCollabSelector = useSelector((state) => state.updateCollab);

  const onSubmit = async (values, { setSubmitting }) => {
    if (updateCollabSelector.loading) {
      return;
    }

    const updatedDocuments = fileURLs.map((document) => ({
      name: document.name,
      type: document.type,
      url: document.urlFirestorage || document.url,
    }));

    const updatedcollab = {
      ...collab,
      ...values,
      courses: values.courses.map((course) => course.name),
      documents: updatedDocuments,
    };

    dispatch(updateCollab(collab?.id, updatedcollab));
    setSubmitting(false);
  };

  const [fileURLs, setFileURLs] = useState([]);
  const [fileUploadProgress, setFileUploadProgress] = useState([]);
  const [uploadTasks, setUploadTasks] = useState([]);
  const [formError, setFormError] = useState([]);
  const storageId = collab?.storageId;

  const [invalidFileType, setInvalidFileType] = useState(false);

  useEffect(() => {
    if (collab?.documents) {
      setFileURLs(
        collab?.documents.map((document) => {
          return { ...document, isUploaded: true };
        })
      );
    }
  }, [collab?.documents]);

  const handleImageInput = () => {
    document.getElementById('image-input').value = '';
  };

  function handleImageChange(event) {
    const selectedFiles = event.target.files;
    const newFileURLs = Array.from(selectedFiles).map((file) =>
      URL.createObjectURL(file)
    );
    setFileURLs((prev) => [
      ...prev,
      ...newFileURLs.map((url, index) => ({
        url,
        name: selectedFiles[index].name,
        type: selectedFiles[index].type,
      })),
    ]);
    Array.from(selectedFiles).forEach((file) => handleUpload(file));
  }

  const user = useSelector((state) => state.userLogin?.userInfo);

  async function handleUpload(file) {
    const allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!allowedMimeTypes.includes(file.type)) {
      setInvalidFileType(true);
      setFormError((prev) => [
        ...prev,
        t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.INVALID_FILE_TYPE'),
      ]);
      return;
    }

    if (user === null) {
      setFormError((prev) => [
        ...prev,
        t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.LOGIN_REQUIRED'),
      ]);
      return;
    }

    if (!file) {
      setFormError((prev) => [
        ...prev,
        t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.NO_FILE_SELECTED'),
      ]);
      return;
    }

    const fileName = file.name.split('.').slice(0, -1).join('.');

    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}-${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    const fileExtension = file.name.split('.').pop();

    const storageRef = ref(
      storage,
      `/collabs-docs/${storageId}/${fileName}-${formattedDate}.${fileExtension}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploadTasks((prev) => [...prev, uploadTask]);

    try {
      setFileUploadProgress((prev) => [
        ...prev,
        { name: file.name, progress: 0 },
      ]);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setFileUploadProgress((prev) =>
            prev.map((item) =>
              item.name === file.name ? { ...item, progress } : item
            )
          );
        },
        (error) => {
          if (error.code === 'storage/canceled') return;

          setFormError((prev) => [
            ...prev,
            t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.UPLOAD_ERROR'),
          ]);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFileURLs((prev) =>
              prev.map((item) =>
                item.name === file.name
                  ? { ...item, urlFirestorage: url }
                  : item
              )
            );
          });
        }
      );
    } catch (error) {
      setFormError((prev) => [
        ...prev,
        t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.UPLOAD_ERROR'),
      ]);
    }
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState({});
  const [fileIndexToDelete, setFileIndexToDelete] = useState(null);

  const handleDeleteDialogOpen = (fileToDelete, index) => {
    setFileToDelete(fileToDelete);
    setFileIndexToDelete(index);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleClearImage = (index) => {
    setFileURLs((prev) => prev.filter((_, i) => i !== index));
    setFileUploadProgress((prev) => prev.filter((_, i) => i !== index));
    setUploadTasks((prev) => prev.filter((_, i) => i !== index));
    setInvalidFileType(false);
    setFormError([]);
  };

  const handleDocDelete = async (index) => {
    try {
      if (uploadTasks[index]) {
        uploadTasks[index].cancel();
      }

      const fileToDelete = fileURLs[index];

      if (fileToDelete?.isUploaded) {
        handleDeleteDialogOpen(fileToDelete, index);
        return;
      }

      handleClearImage(index);
    } catch (error) {
      setFormError((prev) => [
        ...prev,
        t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.DELETE_ERROR'),
      ]);
    }
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      open={editDialogOpen}
      onClose={handleEditDialogClose}
      aria-labelledby="edit-collab-dialog-title"
    >
      <DialogContent sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
        <Formik
          initialValues={{
            name: capitalizeFirstLetter(collab?.name, t),
            surname: capitalizeFirstLetter(collab?.surname, t),
            dateOfBirth: collab?.dateOfBirth,
            phoneNumber: collab?.phoneNumber,
            address: collab?.address,
            city: collab?.city,
            zipcode: collab?.zipcode,
            taxId: collab?.taxId,
            customersCount: collab?.customersCount,
            courses:
              collab?.courses?.map((course) => {
                return { name: course };
              }) || [],
            documents: collab?.documents,
            notes: collab?.notes,
            email: collab?.email,
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={() => updateCollabSchema(t)}
          onSubmit={onSubmit}
        >
          {() => {
            return (
              <Stack gap={2} justifyContent={'center'}>
                <Stack
                  direction={'row'}
                  gap={2}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      color: 'primary.neutral800',
                      fontWeight: '500',
                      fontSize: '20px',
                    }}
                  >
                    {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.TITLE')}
                  </Typography>
                  <IconButton
                    onClick={handleEditDialogClose}
                    sx={{
                      p: 0,
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <Close />
                  </IconButton>
                </Stack>
                <Form
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                  id="edit-collab-form"
                  name="edit-collab-form"
                >
                  <Stack
                    gap={{
                      xs: 0,
                      md: 2,
                    }}
                    direction={{ xs: 'column', md: 'row' }}
                  >
                    <Stack gap={2} direction={'row'} sx={{ width: '100%' }}>
                      <CustomInput
                        label={t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.NAME')}
                        name="name"
                        id="name"
                        type="text"
                        autoComplete="off"
                        placeholder={t(
                          'ADMIN.COLLABS.EDIT_COLLAB_MODAL.NAME_PLACEHOLDER'
                        )}
                        manualMarginBottom={'16px'}
                      />
                      <CustomInput
                        label={t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.SURNAME')}
                        name="surname"
                        id="surname"
                        type="text"
                        autoComplete="off"
                        placeholder={t(
                          'ADMIN.COLLABS.EDIT_COLLAB_MODAL.SURNAME_PLACEHOLDER'
                        )}
                        manualMarginBottom={'16px'}
                      />
                    </Stack>

                    <Stack gap={2} direction={'row'} sx={{ width: '100%' }}>
                      <CustomInput
                        label={t(
                          'ADMIN.COLLABS.EDIT_COLLAB_MODAL.DATE_OF_BIRTH'
                        )}
                        name="dateOfBirth"
                        id="dateOfBirth"
                        type="date"
                        manualMarginBottom={'16px'}
                      />
                      <CustomInput
                        label={t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.TAX_ID')}
                        name="taxId"
                        id="taxId"
                        type="text"
                        autoComplete="off"
                        placeholder={t(
                          'ADMIN.COLLABS.EDIT_COLLAB_MODAL.TAX_ID_PLACEHOLDER'
                        )}
                        manualMarginBottom={'16px'}
                      />
                    </Stack>
                  </Stack>
                  <Stack gap={2} direction={'row'} sx={{ width: '100%' }}>
                    <CustomInput
                      label={t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.ADDRESS')}
                      name="address"
                      id="address"
                      type="text"
                      autoComplete="off"
                      placeholder={t(
                        'ADMIN.COLLABS.EDIT_COLLAB_MODAL.ADDRESS_PLACEHOLDER'
                      )}
                      manualMarginBottom={'16px'}
                    />
                    <CustomInput
                      label={t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.ZIPCODE')}
                      name="zipcode"
                      id="zipcode"
                      type="text"
                      autoComplete="off"
                      placeholder={t(
                        'ADMIN.COLLABS.EDIT_COLLAB_MODAL.ZIPCODE_PLACEHOLDER'
                      )}
                      manualMarginBottom={'16px'}
                    />
                  </Stack>

                  <Stack
                    gap={{ xs: 0, md: 2 }}
                    direction={{ xs: 'column', md: 'row' }}
                  >
                    <Stack gap={2} direction={'row'} width={'100%'}>
                      <CustomInput
                        label={t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.CITY')}
                        name="city"
                        id="city"
                        type="text"
                        autoComplete="off"
                        placeholder={t(
                          'ADMIN.COLLABS.EDIT_COLLAB_MODAL.CITY_PLACEHOLDER'
                        )}
                        manualMarginBottom={'16px'}
                      />
                      <CustomInput
                        label={t(
                          'ADMIN.COLLABS.EDIT_COLLAB_MODAL.PHONE_NUMBER'
                        )}
                        name="phoneNumber"
                        id="phoneNumber"
                        type="text"
                        autoComplete="off"
                        placeholder={t(
                          'ADMIN.COLLABS.EDIT_COLLAB_MODAL.PHONE_NUMBER_PLACEHOLDER'
                        )}
                        manualMarginBottom={'16px'}
                      />
                    </Stack>
                    <CustomAutocomplete
                      label={t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.COURSES')}
                      name={`courses`}
                      id={`courses`}
                      options={collabsCourses.map((course) => course)}
                      placeholder={t(
                        'ADMIN.COLLABS.EDIT_COLLAB_MODAL.COURSES_PLACEHOLDER'
                      )}
                      disableCloseOnSelect
                      manualMarginBottom={'16px'}
                    />
                  </Stack>

                  <Stack gap={2} direction={'row'} sx={{ width: '100%' }}>
                    <CustomInput
                      label={t(
                        'ADMIN.COLLABS.EDIT_COLLAB_MODAL.CUSTOMERS_COUNT'
                      )}
                      name="customersCount"
                      id="customersCount"
                      type="number"
                      autoComplete="off"
                      placeholder={t(
                        'ADMIN.COLLABS.EDIT_COLLAB_MODAL.CUSTOMERS_COUNT_PLACEHOLDER'
                      )}
                      manualMarginBottom={'16px'}
                    />
                    <CustomInput
                      label={t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.EMAIL')}
                      name="email"
                      id="email"
                      type="email"
                      disabled
                      autoComplete="off"
                      placeholder={t(
                        'ADMIN.COLLABS.EDIT_COLLAB_MODAL.EMAIL_PLACEHOLDER'
                      )}
                      manualMarginBottom={'16px'}
                    />
                  </Stack>

                  <CustomTextArea
                    label={t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.NOTES')}
                    name="notes"
                    id="notes"
                    type="text"
                    autoComplete="off"
                    placeholder={t(
                      'ADMIN.COLLABS.EDIT_COLLAB_MODAL.NOTES_PLACEHOLDER'
                    )}
                    manualMarginBottom={'16px'}
                    multiline
                    rows={2}
                  />
                  <Stack gap={1} sx={{ marginBottom: '16px' }}>
                    <Typography
                      component={'label'}
                      sx={{ fontSize: '16px', fontWeight: '500' }}
                    >
                      {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.DOCUMENTS')}
                    </Typography>
                    <Stack gap={2}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          width: '100%',
                          border: '2px dashed',
                          borderColor: 'primary.primary500',
                          gap: '8px',
                          borderRadius: theme.shape.defaultBorderRadius,
                          p: '10px',
                        }}
                      >
                        <ImageOutlined sx={{ color: 'primary.main' }} />
                        <Typography
                          variant="h3"
                          sx={{
                            fontSize: '16px',
                            color: 'text.secondary',
                            textAlign: 'center',
                          }}
                        >
                          {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.DRAG_DROP')}
                        </Typography>
                        <Link
                          component="label"
                          onClick={handleImageInput}
                          sx={{
                            cursor: 'pointer',
                            textDecoration: 'none',
                            fontWeight: '500',
                          }}
                        >
                          {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.BROWSE')}
                          <input
                            id="image-input"
                            name="image"
                            type="file"
                            accept="image/jpeg, image/png, application/pdf"
                            multiple
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                          />
                        </Link>
                      </Box>

                      {formError.length > 0 && (
                        <Alert
                          severity="error"
                          sx={{
                            borderRadius: theme.shape.defaultBorderRadius,
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.error"
                            component="div"
                          >
                            {formError.map((error, index) => (
                              <div key={index}>{error}</div>
                            ))}
                          </Typography>
                        </Alert>
                      )}

                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        gap={2}
                        justifyContent="flex-start"
                      >
                        {fileURLs.map((file, index) => (
                          <Stack
                            key={index}
                            direction={'row'}
                            gap={1}
                            alignItems={'center'}
                          >
                            <Box sx={{ height: '100%', position: 'relative' }}>
                              {file.type === 'application/pdf' ? (
                                <Box
                                  sx={{
                                    width: '64px',
                                    height: '64px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: '1px solid',
                                    borderColor: 'primary.neutral300',
                                    borderRadius: theme.shape.chipBorderRadius,
                                  }}
                                >
                                  <Article
                                    sx={{
                                      fontSize: '32px',
                                      color: 'primary.neutral500',
                                    }}
                                  />
                                </Box>
                              ) : (
                                <img
                                  src={file.url}
                                  alt={`file-preview-${index}`}
                                  loading="lazy"
                                  style={{
                                    width: '64px',
                                    height: '64px',
                                    objectFit: 'cover',
                                    borderRadius: theme.shape.chipBorderRadius,
                                  }}
                                />
                              )}
                              <IconButton
                                onClick={() => handleDocDelete(index)}
                                sx={{
                                  position: 'absolute',
                                  top: 4,
                                  right: 4,
                                  width: '18px',
                                  height: '18px',
                                  backgroundColor: 'rgba(0,0,0,0.5)',
                                  color: 'white',
                                  '&:hover': {
                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                  },
                                }}
                              >
                                <Close sx={{ fontSize: '16px' }} />
                              </IconButton>
                              <Box
                                sx={{
                                  position: 'absolute',
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  backgroundColor: file.isUploaded
                                    ? 'success.main'
                                    : 'primary.main',
                                  borderRadius: `0 0 ${theme.shape.chipBorderRadius} ${theme.shape.chipBorderRadius}`,
                                  color: 'white',
                                  fontSize: '12px',
                                  textAlign: 'center',
                                }}
                              >
                                {file.isUploaded ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: 1,
                                    }}
                                  >
                                    <Done sx={{ fontSize: '16px' }} />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: 1,
                                    }}
                                  >
                                    {fileUploadProgress.find(
                                      (item) => item.name === file.name
                                    )?.progress || 0}
                                    %
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            <Typography
                              variant="body2"
                              color="primary.neutral800"
                              sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                              }}
                            >
                              {file.name}
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    gap={2}
                    direction={{ xs: 'column', md: 'row' }}
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: { xs: 'flex-end', md: 'center' },
                    }}
                  >
                    <Stack gap={2}>
                      {updateCollabSelector.success && (
                        <Alert
                          severity="success"
                          sx={{
                            backgroundColor: 'transparent',
                            py: 0,
                            px: 1,
                          }}
                        >
                          {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.SUCCESS_MESSAGE')}
                        </Alert>
                      )}
                      {updateCollabSelector.error && (
                        <Alert
                          severity="error"
                          sx={{
                            backgroundColor: 'transparent',
                            py: 0,
                            px: 1,
                          }}
                        >
                          {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.ERROR_MESSAGE')}
                        </Alert>
                      )}
                    </Stack>

                    <DialogActions sx={{ display: 'flex', gap: 1, p: 0 }}>
                      <StyledButton
                        onClick={handleEditDialogClose}
                        sx={{
                          backgroundColor: 'transparent',
                          border: '1px solid',
                          borderColor: 'primary.neutral200',
                          color: 'primary.neutral600',
                          ...buttonTransition,
                          '&:hover': {
                            backgroundColor: alpha(
                              theme.palette.primary.neutral200,
                              0.2
                            ),
                            borderColor: theme.palette.primary.neutral200,
                          },
                        }}
                      >
                        {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.CLOSE')}
                      </StyledButton>
                      <StyledButton
                        autoFocus
                        sx={{
                          backgroundColor: 'primary.main',
                          color: 'primary.white',
                          '&:hover': {
                            backgroundColor: lighten(
                              theme.palette.primary.main,
                              0.1
                            ),
                          },
                          '&:disabled': {
                            backgroundColor: theme.palette.primary.neutral200,
                            color: theme.palette.primary.neutral600,
                          },
                        }}
                        type="submit"
                        onSubmit={onSubmit}
                        disabled={
                          updateCollabSelector.loading ||
                          invalidFileType ||
                          fileUploadProgress.some((item) => item.progress < 100)
                        }
                      >
                        {t('ADMIN.COLLABS.EDIT_COLLAB_MODAL.SAVE')}
                        {updateCollabSelector.loading && (
                          <CircularProgress color="inherit" size={'16px'} />
                        )}
                      </StyledButton>
                    </DialogActions>
                  </Stack>
                </Form>
                {updateCollabSelector.loading && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Stack>
            );
          }}
        </Formik>
      </DialogContent>
      <DeleteFileDialog
        deleteDialogOpen={deleteDialogOpen}
        handleDeleteDialogClose={handleDeleteDialogClose}
        collabId={collab?.id}
        fileToDelete={fileToDelete}
        fileIndexToDelete={fileIndexToDelete}
        handleClearImage={handleClearImage}
        dispatch={dispatch}
        theme={theme}
        t={t}
      />
    </Dialog>
  );
}

export default EditCollabDialog;
