import {
  Timestamp,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  endAt,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  limit,
  limitToLast,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
} from 'firebase/firestore';
import QRCode from 'qrcode';
import emailjs from '@emailjs/browser';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from 'firebase/auth';
import { deleteObject, listAll, ref } from 'firebase/storage';

import { auth, googleProvider, storage } from '../config/firebase';
import { Providers, UserRoles } from '../components/Utils/UIUtils';
import { capitalizeFirstLetter } from '../components/Utils/Formating';

const getErrorMessage = (error) => {
  let errorMessage =
    process.env.REACT_APP_ENV === 'development'
      ? error.message
      : 'Something went wrong. Please try again later, or contact support.';
  if (error.code === 'auth/user-not-found') {
    errorMessage = 'User not found';
  } else if (error.code === 'auth/wrong-password') {
    errorMessage = 'Invalid password';
  } else if (error.code === 'auth/email-already-in-use') {
    errorMessage = 'Email already in use';
  } else if (error.code === 'auth/weak-password') {
    errorMessage = 'Password is too weak';
  } else if (error.code === 'auth/too-many-requests') {
    errorMessage = 'Too many requests. Try again later';
  } else if (error.code === 'auth/invalid-login-credentials') {
    errorMessage = 'Invalid login credentials';
  } else if (error.code === 'auth/invalid-email') {
    errorMessage = 'Invalid email';
  }
  return errorMessage;
};

const convertTimestamps = (data) => {
  const scans = { ...data.scans };
  Object.keys(scans).forEach((monthKey) => {
    scans[monthKey] = scans[monthKey].map((scan) => ({
      ...scan,
      timestamp: scan.timestamp.toMillis(),
    }));
  });
  return {
    ...data,
    scans,
    createdAt: data.createdAt.toMillis(),
  };
};

const convertCollabTimestamps = (data) => {
  const scans = { ...data.scans };
  Object.keys(scans).forEach((monthKey) => {
    if (Array.isArray(scans[monthKey])) {
      scans[monthKey] = scans[monthKey].map((scan) => ({
        ...scan,
        timestamp: scan.timestamp.toMillis(),
      }));
    }
  });
  return {
    ...data,
    scans,
    createdAt: data.createdAt.toMillis(),
  };
};

function generateRandomColor() {
  const colors = [
    '#3d8ec2',
    '#6211ee',
    '#44bba7',
    '#f9c851',
    '#f9627d',
    '#f9627d',
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  const bgColor = colors[randomIndex];
  return bgColor;
}

export const logout = () => async (dispatch) => {
  try {
    await auth.signOut();

    localStorage.removeItem('userInfo');
    dispatch({
      type: 'USER_LOGOUT_SUCCESS',
    });
  } catch (error) {
    dispatch({
      type: 'USER_LOGOUT_FAILED',
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const login = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: 'USER_LOGIN_REQUEST',
    });

    const authData = await signInWithEmailAndPassword(
      auth,
      formData.email,
      formData.password
    );

    const user = authData.user;

    const db = getFirestore();
    const q = query(collection(db, 'users'), where('id', '==', user.uid));
    const qs = await getDocs(q);

    const data = {
      ...qs.docs[0].data(),
      id: authData.user.uid,
      email: authData.user.email,
      displayName: authData.user.displayName,
      photoURL: authData.user.photoURL,
    };

    dispatch({
      type: 'USER_LOGIN_SUCCESS',
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: 'USER_LOGIN_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const signInWithGoogle = () => async (dispatch) => {
  try {
    dispatch({
      type: 'USER_LOGIN_REQUEST',
    });

    const authData = await signInWithPopup(auth, googleProvider);

    const db = getFirestore();

    const q1 = query(
      collection(db, 'users'),
      where('id', '==', authData.user.uid)
    );

    const qs1 = await getDocs(q1);
    let data;
    if (qs1.size === 0) {
      data = {
        id: authData.user.uid,
        email: authData.user.email,
        displayName: authData.user.displayName,
        photoURL: authData.user.photoURL,
        phoneNumber: authData.user.phoneNumber,
        profileColor: generateRandomColor(),
        provider: Providers.google,
        roles: [UserRoles.CUSTOMER],
      };
      await setDoc(doc(db, 'users', authData.user.uid), data);
    } else {
      data = {
        id: authData.user.uid,
        email: authData.user.email,
        displayName: authData.user.displayName,
        photoURL: authData.user.photoURL,
        ...qs1.docs[0].data(),
      };
    }

    dispatch({
      type: 'USER_LOGIN_SUCCESS',
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: 'USER_LOGIN_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const register = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: 'USER_REGISTER_REQUEST',
    });

    const db = getFirestore();

    const authData = await createUserWithEmailAndPassword(
      auth,
      formData.email,
      formData.password
    );

    const user = authData.user;
    await updateProfile(user, {
      displayName: formData.fullName.toLowerCase(),
    });

    const data = {
      id: authData.user.uid,
      email: authData.user.email,
      displayName: authData.user.displayName,
      photoURL: authData.user.photoURL,
      profileColor: generateRandomColor(),
      provider: Providers.email,
      roles: [UserRoles.CUSTOMER],
      phoneNumber: '',
    };

    await setDoc(doc(db, 'users', authData.user.uid), data);

    dispatch({
      type: 'USER_REGISTER_SUCCESS',
      payload: data,
    });

    dispatch({
      type: 'USER_LOGIN_SUCCESS',
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: 'USER_REGISTER_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const authStateChanged = (user) => async (dispatch) => {
  try {
    dispatch({
      type: 'USER_AUTH_STATE_CHANGED_REQUEST',
    });

    if (user) {
      const db = getFirestore();
      const q = query(collection(db, 'users'), where('id', '==', user.uid));
      const qs = await getDocs(q);

      const data = {
        ...qs.docs[0].data(),
        id: user.uid,
        email: user.email,
        displayName: qs.docs[0].data().displayName,
        photoURL: user.photoURL,
      };

      dispatch({
        type: 'USER_AUTH_STATE_CHANGED_SUCCESS',
        payload: data,
      });

      localStorage.setItem('userInfo', JSON.stringify(data));
    } else {
      dispatch({
        type: 'USER_AUTH_STATE_CHANGED_FAILED',
        payload: null,
      });

      localStorage.removeItem('userInfo');
    }
  } catch (error) {
    dispatch({
      type: 'USER_AUTH_STATE_CHANGED_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const listCustomers =
  (dir = 'next', pageSize) =>
  async (dispatch, getState) => {
    try {
      const {
        listCustomers: { customers },
      } = getState();

      dispatch({
        type: 'LIST_CUSTOMERS_REQUEST',
      });

      const db = getFirestore();

      let q0;
      if (dir === 'next') {
        if (customers.length > 0) {
          const lastCustomer = customers[customers.length - 1];
          q0 = query(
            collection(db, 'customers'),
            orderBy('name', 'asc'),
            orderBy('surname', 'asc'),
            startAfter(lastCustomer.name, lastCustomer.surname),
            limit(pageSize)
          );
        } else {
          q0 = query(
            collection(db, 'customers'),
            orderBy('name', 'asc'),
            orderBy('surname', 'asc'),
            limit(pageSize)
          );
        }
      } else if (dir === 'prev') {
        if (customers.length > 0) {
          const firstCustomer = customers[0];
          q0 = query(
            collection(db, 'customers'),
            orderBy('name', 'asc'),
            orderBy('surname', 'asc'),
            endBefore(firstCustomer.name, firstCustomer.surname),
            limitToLast(pageSize)
          );
        } else {
          q0 = query(
            collection(db, 'customers'),
            orderBy('name', 'asc'),
            orderBy('surname', 'asc'),
            limitToLast(pageSize)
          );
        }
      } else {
        q0 = query(
          collection(db, 'customers'),
          orderBy('name', 'asc'),
          orderBy('surname', 'asc'),
          limit(pageSize)
        );
      }

      const qs0 = await getDocs(q0);
      const data = qs0.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toMillis(),
          scans: convertTimestamps(doc.data()),
        };
      });

      const q1 = query(collection(db, 'customers'));
      const qs1 = await getCountFromServer(q1);
      const count = qs1.data().count;

      dispatch({
        type: 'LIST_CUSTOMERS_SUCCESS',
        payload: {
          customers: data,
          count: count,
        },
      });
    } catch (error) {
      dispatch({
        type: 'LIST_CUSTOMERS_FAILED',
        payload: error.message,
      });
    }
  };

export const createCustomer = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'CREATE_CUSTOMER_REQUEST',
    });

    const db = getFirestore();

    const {
      userLogin: { userAuth },
    } = getState();

    const q0 = query(
      collection(db, 'users'),
      where('id', '==', userAuth.user.id)
    );
    const qs0 = await getDocs(q0);

    if (qs0.docs[0].data().roles.includes(UserRoles.SUPER) === false) {
      dispatch({
        type: 'CREATE_CUSTOMER_FAILED',
        payload: 'Unauthorized action',
      });
      return;
    }

    const data = {
      ...formData,
      name: formData.name.toLowerCase(),
      surname: formData.surname.toLowerCase(),
      storageId: formData.storageId,
      createdAt: Timestamp.now(),
      createdBy: userAuth.user.id,
      lessonsLeft: formData.paidLessonsCount,
    };

    const docRef = await addDoc(collection(db, 'customers'), data);

    const qrCode = await QRCode.toDataURL('customer-' + docRef.id, {
      type: 'image/png',
    });

    await updateDoc(doc(db, 'customers', docRef.id), {
      qrCode: qrCode,
      id: docRef.id,
    });

    dispatch({
      type: 'CREATE_CUSTOMER_SUCCESS',
      payload: {
        id: docRef.id,
        ...data,
        createdAt: data.createdAt.toMillis(),
        qrCode,
      },
    });

    dispatch({
      type: 'PUSH_LIST_CUSTOMERS_SUCCESS',
      payload: {
        id: docRef.id,
        ...data,
        createdAt: data.createdAt.toMillis(),
        qrCode,
      },
    });
  } catch (error) {
    dispatch({
      type: 'CREATE_CUSTOMER_FAILED',
      payload: 'Something went wrong. Please try again later.',
    });
  }
};

export const deleteCustomer = (id, storageId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'DELETE_CUSTOMER_REQUEST',
    });

    const db = getFirestore();

    const {
      userLogin: { userAuth },
    } = getState();

    const q0 = query(
      collection(db, 'users'),
      where('id', '==', userAuth.user.id)
    );
    const qs0 = await getDocs(q0);

    if (qs0.docs[0].data().roles.includes(UserRoles.SUPER) === false) {
      dispatch({
        type: 'DELETE_CUSTOMER_FAILED',
        payload: 'Unauthorized action',
      });
      return;
    }

    await deleteDoc(doc(db, 'customers', id));

    const storageRef = ref(storage, `customers-docs/${storageId}`);
    const files = await listAll(storageRef);
    files.items.forEach((fileRef) => {
      deleteObject(fileRef);
    });

    dispatch({
      type: 'DELETE_CUSTOMER_SUCCESS',
      payload: id,
    });

    dispatch({
      type: 'POP_LIST_CUSTOMERS_SUCCESS',
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: 'DELETE_CUSTOMER_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const updateCustomer = (id, formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'UPDATE_CUSTOMER_REQUEST',
    });

    const db = getFirestore();

    const {
      userLogin: { userAuth },
    } = getState();

    const q0 = query(
      collection(db, 'users'),
      where('id', '==', userAuth.user.id)
    );
    const qs0 = await getDocs(q0);

    if (
      qs0.docs[0].data().roles.includes(UserRoles.SUPER) === false &&
      qs0.docs[0].data().roles.includes(UserRoles.ADMINISTRATOR) === false
    ) {
      dispatch({
        type: 'UPDATE_CUSTOMER_FAILED',
        payload: 'Unauthorized action',
      });
      return;
    }

    const data = {
      name: formData.name.toLowerCase(),
      surname: formData.surname.toLowerCase(),
      dateOfBirth: formData.dateOfBirth,
      address: formData.address,
      city: formData.city,
      zipcode: formData.zipcode,
      courses: formData.courses,
      taxId: formData.taxId,
      paidLessonsCount: formData.paidLessonsCount,
      lessonsLeft: formData.lessonsLeft,
      documents: formData.documents,
      notes: formData.notes,
      phoneNumber: formData.phoneNumber,
      email: formData.email.toLowerCase(),
    };

    await updateDoc(doc(db, 'customers', id), data);

    dispatch({
      type: 'UPDATE_CUSTOMER_SUCCESS',
      payload: {
        id,
        ...data,
      },
    });

    dispatch({
      type: 'UPDATE_LIST_CUSTOMERS_SUCCESS',
      payload: {
        id,
        ...data,
      },
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_CUSTOMER_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const deleteDocumentFromDB =
  (userId, fileName, collectionName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: 'DELETE_DOCUMENT_REQUEST',
      });

      const db = getFirestore();

      const {
        userLogin: { userAuth },
      } = getState();

      const q0 = query(
        collection(db, 'users'),
        where('id', '==', userAuth.user.id)
      );
      const qs0 = await getDocs(q0);

      if (qs0.docs[0].data().roles.includes(UserRoles.SUPER) === false) {
        dispatch({
          type: 'DELETE_DOCUMENT_FAILED',
          payload: 'Unauthorized action',
        });
        return;
      }

      const q1 = query(
        collection(db, collectionName),
        where('id', '==', userId)
      );
      const qs1 = await getDocs(q1);

      const documents = qs1.docs[0]
        .data()
        .documents.filter((doc) => doc.name !== fileName);

      await updateDoc(doc(db, collectionName, userId), { documents });

      if (collectionName === 'customers') {
        dispatch({
          type: 'UPDATE_LIST_CUSTOMERS_SUCCESS',
          payload: {
            id: userId,
            documents,
          },
        });
      } else if (collectionName === 'collaborators') {
        dispatch({
          type: 'UPDATE_LIST_COLLABS_SUCCESS',
          payload: {
            id: userId,
            documents,
          },
        });
      }

      dispatch({
        type: 'DELETE_DOCUMENT_SUCCESS',
        payload: {
          id: userId,
          documents,
        },
      });
    } catch (error) {
      dispatch({
        type: 'DELETE_DOCUMENT_FAILED',
        payload: getErrorMessage(error),
      });
    }
  };

export const handleCustomerQrScan = (customerId) => async (dispatch) => {
  try {
    dispatch({
      type: 'HANDLE_QR_SCAN_REQUEST',
    });

    const db = getFirestore();
    const customerDocRef = doc(db, 'customers', customerId);
    const customerDoc = await getDoc(customerDocRef);

    if (!customerDoc.exists()) {
      dispatch({
        type: 'HANDLE_QR_SCAN_FAILED',
        payload: 'Cliente non trovato',
      });
      return;
    }

    const customerData = customerDoc.data();
    const newLessonsCount = customerData.lessonsLeft - 1;

    if (customerData.lessonsLeft === 0) {
      dispatch({
        type: 'HANDLE_QR_SCAN_FAILED',
        payload: 'Nessuna lezione rimasta',
      });
      return;
    }

    const today = Timestamp.now();
    const dateKey = `${today.toDate().getMonth() + 1}-${today
      .toDate()
      .getFullYear()}`;

    const scanData = {
      timestamp: today,
    };

    const updateData = {
      lessonsLeft: newLessonsCount,
      [`scans.${dateKey}`]: arrayUnion(scanData),
    };

    if (newLessonsCount === 2) {
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          'template_lessons_alert',
          {
            customer_name: `${capitalizeFirstLetter(
              customerData.name
            )} ${capitalizeFirstLetter(customerData.surname)}`,
            customer_id: customerData.id,
          },
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          async () => {
            await updateDoc(customerDocRef, updateData);

            dispatch({
              type: 'HANDLE_QR_SCAN_SUCCESS',
              payload: {
                lessons: newLessonsCount,
                fullName: `${capitalizeFirstLetter(
                  customerData.name
                )} ${capitalizeFirstLetter(customerData.surname)}`,
              },
            });
          },
          (error) => {
            dispatch({
              type: 'HANDLE_QR_SCAN_FAILED',
              payload: error.text,
            });
          }
        );
    } else {
      await updateDoc(customerDocRef, updateData);

      dispatch({
        type: 'HANDLE_QR_SCAN_SUCCESS',
        payload: {
          lessons: newLessonsCount,
          fullName: `${capitalizeFirstLetter(
            customerData.name
          )} ${capitalizeFirstLetter(customerData.surname)}`,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: 'HANDLE_QR_SCAN_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const listCustomersBySearch = (search) => async (dispatch) => {
  try {
    dispatch({
      type: 'LIST_CUSTOMERS_REQUEST',
    });

    const db = getFirestore();
    const customersRef = collection(db, 'customers');

    const searchID = search;
    const searchName = search.toLowerCase();

    const nameQuery = query(
      customersRef,
      orderBy('name'),
      startAt(searchName),
      endAt(searchName + '\uf8ff')
    );

    const surnameQuery = query(
      customersRef,
      orderBy('surname'),
      startAt(searchName),
      endAt(searchName + '\uf8ff')
    );

    const idQuery = query(
      customersRef,
      orderBy('id'),
      where('id', '==', searchID)
    );

    const [nameSnapshot, surnameSnapshot] = await Promise.all([
      getDocs(nameQuery),
      getDocs(surnameQuery),
    ]);

    const nameResults = nameSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toMillis(),
    }));

    const surnameResults = surnameSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toMillis(),
    }));

    const [idSnapshot] = await Promise.all([getDocs(idQuery)]);

    if (idSnapshot.docs.length > 0) {
      const idResult = idSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toMillis(),
      }));

      dispatch({
        type: 'LIST_CUSTOMERS_SUCCESS',
        payload: {
          customers: idResult,
          count: idResult.length,
        },
      });
      return;
    }

    const combinedResults = [...nameResults, ...surnameResults];
    const uniqueResults = Array.from(
      new Set(combinedResults.map((a) => a.id))
    ).map((id) => {
      return combinedResults.find((a) => a.id === id);
    });

    dispatch({
      type: 'LIST_CUSTOMERS_SUCCESS',
      payload: {
        customers: uniqueResults,
        count: uniqueResults.length,
      },
    });
  } catch (error) {
    dispatch({
      type: 'LIST_CUSTOMERS_FAILED',
      payload: error.message,
    });
  }
};

export const listCollabs =
  (dir = 'next', pageSize) =>
  async (dispatch, getState) => {
    try {
      const {
        listCollabs: { collabs },
      } = getState();

      dispatch({
        type: 'LIST_COLLABS_REQUEST',
      });

      const {
        userLogin: { userAuth },
      } = getState();

      if (!userAuth.user.roles.includes(UserRoles.SUPER)) {
        dispatch({
          type: 'LIST_COLLABS_FAILED',
          payload: 'Unauthorized action',
        });
        return;
      }

      const db = getFirestore();

      let q0;
      if (dir === 'next') {
        if (collabs.length > 0) {
          const lastCollab = collabs[collabs.length - 1];
          q0 = query(
            collection(db, 'collaborators'),
            orderBy('name', 'asc'),
            orderBy('surname', 'asc'),
            startAfter(lastCollab.name, lastCollab.surname),
            limit(pageSize)
          );
        } else {
          q0 = query(
            collection(db, 'collaborators'),
            orderBy('name', 'asc'),
            orderBy('surname', 'asc'),
            limit(pageSize)
          );
        }
      } else if (dir === 'prev') {
        if (collabs.length > 0) {
          const firstCollab = collabs[0];
          q0 = query(
            collection(db, 'collaborators'),
            orderBy('name', 'asc'),
            orderBy('surname', 'asc'),
            endBefore(firstCollab.name, firstCollab.surname),
            limitToLast(pageSize)
          );
        } else {
          q0 = query(
            collection(db, 'collaborators'),
            orderBy('name', 'asc'),
            orderBy('surname', 'asc'),
            limitToLast(pageSize)
          );
        }
      } else {
        q0 = query(
          collection(db, 'collaborators'),
          orderBy('name', 'asc'),
          orderBy('surname', 'asc'),
          limit(pageSize)
        );
      }

      const qs0 = await getDocs(q0);
      const data = qs0.docs.map((doc) => {
        const docData = doc.data();
        const serializedScans = {};
        if (docData.scans) {
          Object.entries(docData.scans).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              serializedScans[key] = value.map((scan) => ({
                ...scan,
                timestamp: scan.timestamp.toMillis(),
              }));
            } else {
              serializedScans[key] = value;
            }
          });
        }

        return {
          id: doc.id,
          ...docData,
          scans: serializedScans,
          createdAt: docData.createdAt.toMillis(),
        };
      });

      const q1 = query(collection(db, 'collaborators'));
      const qs1 = await getCountFromServer(q1);
      const count = qs1.data().count;

      dispatch({
        type: 'LIST_COLLABS_SUCCESS',
        payload: {
          collabs: data,
          count: count,
        },
      });
    } catch (error) {
      dispatch({
        type: 'LIST_COLLABS_FAILED',
        payload: error.message,
      });
    }
  };

export const listCollabsBySearch = (search) => async (dispatch) => {
  try {
    dispatch({
      type: 'LIST_COLLABS_REQUEST',
    });

    const db = getFirestore();
    const collabsRef = collection(db, 'collaborators');

    const searchID = search;
    const searchName = search.toLowerCase();

    const nameQuery = query(
      collabsRef,
      orderBy('name'),
      startAt(searchName),
      endAt(searchName + '\uf8ff')
    );

    const surnameQuery = query(
      collabsRef,
      orderBy('surname'),
      startAt(searchName),
      endAt(searchName + '\uf8ff')
    );

    const idQuery = query(
      collabsRef,
      orderBy('id'),
      where('id', '==', searchID)
    );

    const mapDocWithSerializedTimestamps = (doc) => {
      const docData = doc.data();
      const serializedScans = {};
      if (docData.scans) {
        Object.entries(docData.scans).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            serializedScans[key] = value.map((scan) => ({
              ...scan,
              timestamp: scan.timestamp.toMillis(),
            }));
          } else {
            serializedScans[key] = value;
          }
        });
      }

      return {
        id: doc.id,
        ...docData,
        scans: serializedScans,
        createdAt: docData.createdAt.toMillis(),
      };
    };

    const [nameSnapshot, surnameSnapshot] = await Promise.all([
      getDocs(nameQuery),
      getDocs(surnameQuery),
    ]);

    const nameResults = nameSnapshot.docs.map(mapDocWithSerializedTimestamps);
    const surnameResults = surnameSnapshot.docs.map(
      mapDocWithSerializedTimestamps
    );

    const [idSnapshot] = await Promise.all([getDocs(idQuery)]);

    if (idSnapshot.docs.length > 0) {
      const idResult = idSnapshot.docs.map(mapDocWithSerializedTimestamps);

      dispatch({
        type: 'LIST_COLLABS_SUCCESS',
        payload: {
          collabs: idResult,
          count: idResult.length,
        },
      });
      return;
    }

    const combinedResults = [...nameResults, ...surnameResults];
    const uniqueResults = Array.from(
      new Set(combinedResults.map((a) => a.id))
    ).map((id) => {
      return combinedResults.find((a) => a.id === id);
    });

    dispatch({
      type: 'LIST_COLLABS_SUCCESS',
      payload: {
        collabs: uniqueResults,
        count: uniqueResults.length,
      },
    });
  } catch (error) {
    dispatch({
      type: 'LIST_COLLABS_FAILED',
      payload: error.message,
    });
  }
};

export const createCollaborator = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'CREATE_COLLAB_REQUEST',
    });

    const db = getFirestore();

    const {
      userLogin: { userAuth },
    } = getState();

    const q0 = query(
      collection(db, 'users'),
      where('id', '==', userAuth.user.id)
    );
    const qs0 = await getDocs(q0);

    if (!qs0.docs[0].data().roles.includes(UserRoles.SUPER)) {
      dispatch({
        type: 'CREATE_COLLAB_FAILED',
        payload: 'Unauthorized action',
      });
      return;
    }

    const data = {
      ...formData,
      name: formData.name.toLowerCase(),
      surname: formData.surname.toLowerCase(),
      createdAt: Timestamp.now(),
      createdBy: userAuth.user.id,
      scans: [],
    };

    const q1 = query(
      collection(db, 'users'),
      where('email', '==', formData.email.toLowerCase())
    );
    const qs1 = await getDocs(q1);

    let docRef;

    if (qs1.docs.length > 0) {
      docRef = await addDoc(collection(db, 'collaborators'), data);
      await updateDoc(doc(db, 'users', qs1.docs[0].id), {
        collabId: docRef.id,
        roles: [...qs1.docs[0].data().roles, UserRoles.COLLAB],
      });
    } else {
      const userData = {
        id: null,
        email: formData.email.toLowerCase(),
        displayName: `${formData.name.toLowerCase()} ${formData.surname.toLowerCase()}`,
        photoURL: '',
        profileColor: generateRandomColor(),
        provider: Providers.email,
        roles: [UserRoles.COLLAB],
        phoneNumber: '',
      };

      docRef = await addDoc(collection(db, 'collaborators'), data);

      userData.collabId = docRef.id;

      await addDoc(collection(db, 'users'), userData);
    }

    const qrCode = await QRCode.toDataURL('collab-' + docRef.id, {
      type: 'image/png',
    });

    await updateDoc(doc(db, 'collaborators', docRef.id), {
      qrCode: qrCode,
      id: docRef.id,
    });

    dispatch({
      type: 'CREATE_COLLAB_SUCCESS',
      payload: {
        id: docRef.id,
        ...data,
        createdAt: data.createdAt.toMillis(),
        qrCode,
      },
    });

    dispatch({
      type: 'PUSH_LIST_COLLABS_SUCCESS',
      payload: {
        id: docRef.id,
        ...data,
        createdAt: data.createdAt.toMillis(),
        qrCode,
      },
    });
  } catch (error) {
    dispatch({
      type: 'CREATE_COLLAB_FAILED',
      payload: 'Something went wrong. Please try again later.',
    });
  }
};

export const deleteCollab = (id, storageId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'DELETE_COLLAB_REQUEST',
    });

    const db = getFirestore();

    const {
      userLogin: { userAuth },
    } = getState();

    const q0 = query(
      collection(db, 'users'),
      where('id', '==', userAuth.user.id)
    );
    const qs0 = await getDocs(q0);

    if (qs0.docs[0].data().roles.includes(UserRoles.SUPER) === false) {
      dispatch({
        type: 'DELETE_COLLAB_FAILED',
        payload: 'Unauthorized action',
      });
      return;
    }

    await deleteDoc(doc(db, 'collaborators', id));

    const storageRef = ref(storage, `collabs-docs/${storageId}`);
    const files = await listAll(storageRef);
    files.items.forEach((fileRef) => {
      deleteObject(fileRef);
    });

    const q1 = query(collection(db, 'users'), where('collabId', '==', id));
    const qs1 = await getDocs(q1);

    if (qs1.docs.length > 0) {
      await updateDoc(doc(db, 'users', qs1.docs[0].id), {
        collabId: null,
        roles: qs1.docs[0]
          .data()
          .roles.filter((role) => role !== UserRoles.COLLAB),
      });
    }

    dispatch({
      type: 'DELETE_COLLAB_SUCCESS',
      payload: id,
    });

    dispatch({
      type: 'POP_LIST_COLLABS_SUCCESS',
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: 'DELETE_COLLAB_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const updateCollab = (id, formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'UPDATE_COLLAB_REQUEST',
    });

    const db = getFirestore();

    const {
      userLogin: { userAuth },
    } = getState();

    const q0 = query(
      collection(db, 'users'),
      where('id', '==', userAuth.user.id)
    );
    const qs0 = await getDocs(q0);

    if (
      qs0.docs[0].data().roles.includes(UserRoles.SUPER) === false &&
      qs0.docs[0].data().roles.includes(UserRoles.ADMINISTRATOR) === false
    ) {
      dispatch({
        type: 'UPDATE_COLLAB_FAILED',
        payload: 'Unauthorized action',
      });
      return;
    }

    const data = {
      name: formData.name.toLowerCase(),
      surname: formData.surname.toLowerCase(),
      dateOfBirth: formData.dateOfBirth,
      address: formData.address,
      city: formData.city,
      zipcode: formData.zipcode,
      taxId: formData.taxId,
      documents: formData.documents,
      notes: formData.notes,
      customersCount: formData.customersCount,
      phoneNumber: formData.phoneNumber,
    };

    const q1 = query(collection(db, 'users'), where('collabId', '==', id));
    const qs1 = await getDocs(q1);

    if (qs1.docs.length > 0) {
      await updateDoc(doc(db, 'users', qs1.docs[0].id), {
        email: formData.email.toLowerCase(),
      });
    }

    await updateDoc(doc(db, 'collaborators', id), data);

    dispatch({
      type: 'UPDATE_COLLAB_SUCCESS',
      payload: {
        id,
        ...data,
      },
    });

    dispatch({
      type: 'UPDATE_LIST_COLLABS_SUCCESS',
      payload: {
        id,
        ...data,
      },
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_COLLAB_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const handleCollabQrScan = (collabId) => async (dispatch) => {
  try {
    dispatch({
      type: 'HANDLE_QR_SCAN_REQUEST',
    });

    const db = getFirestore();
    const collabDocRef = doc(db, 'collaborators', collabId);
    const collabDoc = await getDoc(collabDocRef);

    if (!collabDoc.exists()) {
      dispatch({
        type: 'HANDLE_QR_SCAN_FAILED',
        payload: 'Collaborator not found',
      });
      return;
    }
    const collabData = collabDoc.data();
    const today = Timestamp.now();
    const dateKey = `${today.toDate().getMonth() + 1}-${today
      .toDate()
      .getFullYear()}`;

    const scanData = {
      timestamp: today,
    };

    const updateData = {
      customersCount: increment(-1),
      [`scans.${dateKey}`]: arrayUnion(scanData),
    };

    await updateDoc(collabDocRef, updateData);

    dispatch({
      type: 'HANDLE_QR_SCAN_SUCCESS',
      payload: {
        fullName: `${capitalizeFirstLetter(
          collabData.name
        )} ${capitalizeFirstLetter(collabData.surname)}`,
      },
    });
  } catch (error) {
    dispatch({
      type: 'HANDLE_QR_SCAN_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const getCollab = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_COLLAB_REQUEST',
    });

    if (!id) {
      dispatch({
        type: 'GET_COLLAB_FAILED',
        payload: 'Data not found',
      });
      return;
    }
    const db = getFirestore();
    const collabDocRef = doc(db, 'collaborators', id);
    const collabDoc = await getDoc(collabDocRef);

    if (!collabDoc.exists()) {
      dispatch({
        type: 'GET_COLLAB_FAILED',
        payload: 'Collaborator not found',
      });
      return;
    }

    const collabData = collabDoc.data();
    const serializedData = convertCollabTimestamps(collabData);

    dispatch({
      type: 'GET_COLLAB_SUCCESS',
      payload: {
        id: collabDoc.id,
        ...serializedData,
      },
    });
  } catch (error) {
    dispatch({
      type: 'GET_COLLAB_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const getCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_CUSTOMER_REQUEST',
    });

    if (!id) {
      dispatch({
        type: 'GET_CUSTOMER_FAILED',
        payload: 'Data not found',
      });
      return;
    }
    const db = getFirestore();
    const customerDocRef = doc(db, 'customers', id);
    const customerDoc = await getDoc(customerDocRef);

    if (!customerDoc.exists()) {
      dispatch({
        type: 'GET_CUSTOMER_FAILED',
        payload: 'Customer not found',
      });
      return;
    }

    const customerData = customerDoc.data();
    const serializedData = convertTimestamps(customerData);

    dispatch({
      type: 'GET_CUSTOMER_SUCCESS',
      payload: {
        id: customerDoc.id,
        ...serializedData,
      },
    });
  } catch (error) {
    dispatch({
      type: 'GET_CUSTOMER_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const deleteCustomerScan =
  (customerId, monthKey, scan) => async (dispatch) => {
    try {
      dispatch({
        type: 'DELETE_CUSTOMER_SCAN_REQUEST',
      });

      const db = getFirestore();
      const customerRef = doc(db, 'customers', customerId);

      const customerDoc = await getDoc(customerRef);
      if (!customerDoc.exists()) {
        throw new Error('Customer not found');
      }

      const currentScans = customerDoc.data().scans[monthKey] || [];

      const firestoreScan = {
        timestamp: Timestamp.fromMillis(scan.timestamp),
      };

      const updateData = {
        ...(currentScans.length === 1
          ? { [`scans.${monthKey}`]: deleteField() }
          : { [`scans.${monthKey}`]: arrayRemove(firestoreScan) }),
        lessonsLeft: increment(1),
      };

      await updateDoc(customerRef, updateData);

      dispatch({
        type: 'DELETE_CUSTOMER_SCAN_SUCCESS',
        payload: {
          monthKey,
          scanId: scan.timestamp,
        },
      });

      dispatch(getCustomer(customerId));
    } catch (error) {
      dispatch({
        type: 'DELETE_CUSTOMER_SCAN_FAILED',
        payload: getErrorMessage(error),
      });
    }
  };

export const deleteCollabScan =
  (collabId, monthKey, scan) => async (dispatch) => {
    try {
      dispatch({
        type: 'DELETE_COLLAB_SCAN_REQUEST',
      });

      const db = getFirestore();
      const collabRef = doc(db, 'collaborators', collabId);

      const collabDoc = await getDoc(collabRef);
      if (!collabDoc.exists()) {
        throw new Error('Collaborator not found');
      }

      const currentScans = collabDoc.data().scans[monthKey] || [];

      const scanToDelete = {
        timestamp: Timestamp.fromMillis(scan.timestamp),
      };

      const updateData = {
        [`scans.${monthKey}`]: arrayRemove(scanToDelete),
        customersCount: increment(1),
      };

      if (currentScans.length === 1) {
        updateData[`scans.${monthKey}`] = deleteField();
      }

      await updateDoc(collabRef, updateData);

      dispatch({
        type: 'DELETE_COLLAB_SCAN_SUCCESS',
        payload: {
          monthKey,
          scanId: scan.timestamp,
        },
      });

      dispatch(getCollab(collabId));
    } catch (error) {
      dispatch({
        type: 'DELETE_COLLAB_SCAN_FAILED',
        payload: getErrorMessage(error),
      });
    }
  };

export const deleteAllCustomerScans = (customerId) => async (dispatch) => {
  try {
    dispatch({ type: 'DELETE_ALL_CUSTOMER_SCANS_REQUEST' });

    const db = getFirestore();
    const customerRef = doc(db, 'customers', customerId);

    const customerDoc = await getDoc(customerRef);

    if (!customerDoc.exists()) {
      throw new Error('Customer not found');
    }

    await updateDoc(customerRef, {
      scans: {},
      lessonsLeft: customerDoc.data().paidLessonsCount,
    });

    dispatch({ type: 'DELETE_ALL_CUSTOMER_SCANS_SUCCESS' });
    dispatch(getCustomer(customerId));
  } catch (error) {
    dispatch({
      type: 'DELETE_ALL_CUSTOMER_SCANS_FAILED',
      payload: getErrorMessage(error),
    });
  }
};

export const deleteAllCollabScans = (collabId) => async (dispatch) => {
  try {
    dispatch({ type: 'DELETE_ALL_COLLAB_SCANS_REQUEST' });

    const db = getFirestore();
    const collabRef = doc(db, 'collaborators', collabId);

    await updateDoc(collabRef, {
      scans: {},
      customersCount: 1500,
    });

    dispatch({ type: 'DELETE_ALL_COLLAB_SCANS_SUCCESS' });
    dispatch(getCollab(collabId));
  } catch (error) {
    dispatch({
      type: 'DELETE_ALL_COLLAB_SCANS_FAILED',
      payload: getErrorMessage(error),
    });
  }
};
