export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case 'USER_LOGIN_REQUEST':
      return { loading: true };
    case 'USER_LOGIN_SUCCESS':
      return { loading: false, userInfo: action.payload };
    case 'USER_LOGIN_FAILED':
      return { loading: false, error: action.payload };
    case 'USER_LOGOUT_SUCCESS':
      return {
        userAuth: { loading: false, user: null },
      };
    case 'USER_LOGOUT_FAILED':
      return { loading: false, error: action.payload };
    case 'USER_AUTH_STATE_CHANGED_REQUEST':
      return { ...state, userAuth: { loading: true } };
    case 'USER_AUTH_STATE_CHANGED_SUCCESS':
      return { ...state, userAuth: { loading: false, user: action.payload } };
    case 'USER_AUTH_STATE_CHANGED_FAILED':
      return { ...state, userAuth: { loading: false, error: action.payload } };
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case 'USER_REGISTER_REQUEST':
      return { loading: true };
    case 'USER_REGISTER_SUCCESS':
      return { loading: false, userInfo: action.payload };
    case 'USER_REGISTER_FAILED':
      return { loading: false, error: action.payload };
    case 'USER_LOGOUT_SUCCESS':
      return { userAuth: { loading: false, user: null } };
    default:
      return state;
  }
};

export const listCustomersReducer = (
  state = { customers: [], count: 0 },
  action
) => {
  switch (action.type) {
    case 'LIST_CUSTOMERS_REQUEST':
      return { loading: true, customers: [] };
    case 'LIST_CUSTOMERS_SUCCESS':
      return {
        loading: false,
        customers: action.payload.customers,
        count: action.payload.count,
      };
    case 'UPDATE_LIST_CUSTOMERS_SUCCESS':
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === action.payload.id) {
          return { ...customer, ...action.payload };
        }
        return customer;
      });
      return {
        loading: false,
        customers: updatedCustomers,
        count: state.count,
      };
    case 'PUSH_LIST_CUSTOMERS_SUCCESS':
      return {
        loading: false,
        customers: [action.payload, ...state.customers],
        count: state.count + 1,
      };
    case 'POP_LIST_CUSTOMERS_SUCCESS':
      const filteredCustomers = state.customers.filter(
        (customer) => customer.id !== action.payload
      );
      return {
        loading: false,
        customers: filteredCustomers,
        count: state.count - 1,
      };
    case 'LIST_CUSTOMERS_FAILED':
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customerCreationReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case 'CREATE_CUSTOMER_REQUEST':
      return { loading: true };
    case 'CREATE_CUSTOMER_SUCCESS':
      return { loading: false, success: true, customer: action.payload };
    case 'CREATE_CUSTOMER_FAILED':
      return { loading: false, error: action.payload };
    case 'CUSTOMER_CREATION_RESET':
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export const customerDeletionReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case 'DELETE_CUSTOMER_REQUEST':
      return { loading: true };
    case 'DELETE_CUSTOMER_SUCCESS':
      return { loading: false, success: true };
    case 'DELETE_CUSTOMER_FAILED':
      return { loading: false, error: action.payload };
    case 'CUSTOMER_DELETION_RESET':
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export const customerUpdateReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_CUSTOMER_REQUEST':
      return { loading: true, success: false };
    case 'UPDATE_CUSTOMER_SUCCESS':
      return { loading: false, success: true, customer: action.payload };
    case 'UPDATE_CUSTOMER_FAILED':
      return { loading: false, error: action.payload };
    case 'CUSTOMER_UPDATE_RESET':
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export const deleteDocumentReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case 'DELETE_DOCUMENT_REQUEST':
      return { loading: true };
    case 'DELETE_DOCUMENT_SUCCESS':
      return { loading: false, success: true };
    case 'DELETE_DOCUMENT_FAILED':
      return { loading: false, error: action.payload };
    case 'DOCUMENT_DELETION_RESET':
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export const handleQRScanReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case 'HANDLE_QR_SCAN_REQUEST':
      return { loading: true };
    case 'HANDLE_QR_SCAN_SUCCESS':
      return { loading: false, success: true, customer: action.payload };
    case 'HANDLE_QR_SCAN_FAILED':
      return { loading: false, error: action.payload };
    case 'HANDLE_QR_SCAN_RESET':
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export const listCollabsReducer = (
  state = { collabs: [], count: 0 },
  action
) => {
  switch (action.type) {
    case 'LIST_COLLABS_REQUEST':
      return { loading: true, collabs: [] };
    case 'LIST_COLLABS_SUCCESS':
      return {
        loading: false,
        collabs: action.payload.collabs,
        count: action.payload.count,
      };
    case 'UPDATE_LIST_COLLABS_SUCCESS':
      const updatedCollabs = state.collabs.map((collab) => {
        if (collab.id === action.payload.id) {
          return { ...collab, ...action.payload };
        }
        return collab;
      });
      return {
        loading: false,
        collabs: updatedCollabs,
        count: state.count,
      };
    case 'PUSH_LIST_COLLABS_SUCCESS':
      return {
        loading: false,
        collabs: [action.payload, ...state.collabs],
        count: state.count + 1,
      };
    case 'POP_LIST_COLLABS_SUCCESS':
      const filteredCollabs = state.collabs.filter(
        (collab) => collab.id !== action.payload
      );
      return {
        loading: false,
        collabs: filteredCollabs,
        count: state.count - 1,
      };
    case 'LIST_COLLABS_FAILED':
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createCollabReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case 'CREATE_COLLAB_REQUEST':
      return { loading: true };
    case 'CREATE_COLLAB_SUCCESS':
      return { loading: false, success: true, collab: action.payload };
    case 'CREATE_COLLAB_FAILED':
      return { loading: false, error: action.payload };
    case 'COLLAB_CREATE_RESET':
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export const deleteCollabReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case 'DELETE_COLLAB_REQUEST':
      return { loading: true };
    case 'DELETE_COLLAB_SUCCESS':
      return { loading: false, success: true };
    case 'DELETE_COLLAB_FAILED':
      return { loading: false, error: action.payload };
    case 'COLLAB_DELETE_RESET':
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export const updateCollabReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_COLLAB_REQUEST':
      return { loading: true };
    case 'UPDATE_COLLAB_SUCCESS':
      return { loading: false, success: true, collab: action.payload };
    case 'UPDATE_COLLAB_FAILED':
      return { loading: false, error: action.payload };
    case 'COLLAB_UPDATE_RESET':
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export const getCollabReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case 'GET_COLLAB_REQUEST':
      return { loading: true };
    case 'GET_COLLAB_SUCCESS':
      return { loading: false, success: true, collab: action.payload };
    case 'GET_COLLAB_FAILED':
      return { loading: false, error: action.payload };
    case 'COLLAB_GET_RESET':
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export const getCustomerReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case 'GET_CUSTOMER_REQUEST':
      return { loading: true };
    case 'GET_CUSTOMER_SUCCESS':
      return { loading: false, success: true, customer: action.payload };
    case 'GET_CUSTOMER_FAILED':
      return { loading: false, error: action.payload };
    case 'CUSTOMER_GET_RESET':
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export const deleteCustomerScanReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
    lastDeletedScan: null,
  },
  action
) => {
  switch (action.type) {
    case 'DELETE_CUSTOMER_SCAN_REQUEST':
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case 'DELETE_CUSTOMER_SCAN_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        lastDeletedScan: {
          monthKey: action.payload.monthKey,
          scanId: action.payload.scanId,
        },
      };
    case 'DELETE_CUSTOMER_SCAN_FAILED':
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case 'DELETE_CUSTOMER_SCAN_RESET':
      return {
        loading: false,
        success: false,
        error: null,
        lastDeletedScan: null,
      };
    default:
      return state;
  }
};

export const deleteCollabScanReducer = (
  state = {
    loading: false,
    success: false,
    error: null,
    lastDeletedScan: null,
  },
  action
) => {
  switch (action.type) {
    case 'DELETE_COLLAB_SCAN_REQUEST':
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case 'DELETE_COLLAB_SCAN_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        lastDeletedScan: {
          monthKey: action.payload.monthKey,
          scanId: action.payload.scanId,
        },
      };
    case 'DELETE_COLLAB_SCAN_FAILED':
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case 'DELETE_COLLAB_SCAN_RESET':
      return {
        loading: false,
        success: false,
        error: null,
        lastDeletedScan: null,
      };
    default:
      return state;
  }
};

export const deleteAllCustomerScansReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case 'DELETE_ALL_CUSTOMER_SCANS_REQUEST':
      return { loading: true };
    case 'DELETE_ALL_CUSTOMER_SCANS_SUCCESS':
      return { loading: false, success: true };
    case 'DELETE_ALL_CUSTOMER_SCANS_FAILED':
      return { loading: false, error: action.payload };
    case 'DELETE_ALL_CUSTOMER_SCANS_RESET':
      return { loading: false };
    default:
      return state;
  }
};
