import { configureStore } from '@reduxjs/toolkit';

import {
  createCollabReducer,
  customerCreationReducer,
  customerDeletionReducer,
  deleteDocumentReducer,
  customerUpdateReducer,
  deleteCollabReducer,
  listCollabsReducer,
  listCustomersReducer,
  updateCollabReducer,
  userLoginReducer,
  userRegisterReducer,
  getCollabReducer,
  handleQRScanReducer,
  getCustomerReducer,
  deleteCustomerScanReducer,
  deleteCollabScanReducer,
  deleteAllCustomerScansReducer,
} from './reducers/userRecucers';
import { drawerReducer } from './reducers/utilsReducers';

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const preloadedState = {
  userLogin: {
    userInfo: userInfoFromStorage,
    userAuth: { loading: false, user: null },
  },
  utils: { drawer: false },
};

const store = configureStore({
  reducer: {
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    utils: drawerReducer,
    listCustomers: listCustomersReducer,
    customerCreation: customerCreationReducer,
    customerDeletion: customerDeletionReducer,
    customerUpdate: customerUpdateReducer,
    deleteDocument: deleteDocumentReducer,
    handleQRScan: handleQRScanReducer,
    listCollabs: listCollabsReducer,
    createCollab: createCollabReducer,
    deleteCollab: deleteCollabReducer,
    updateCollab: updateCollabReducer,
    collab: getCollabReducer,
    customer: getCustomerReducer,
    deleteCustomerScan: deleteCustomerScanReducer,
    deleteCollabScan: deleteCollabScanReducer,
    deleteAllCustomerScans: deleteAllCustomerScansReducer,
  },
  preloadedState: {
    ...preloadedState,
  },
});

export default store;
