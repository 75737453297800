import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  alpha,
  Box,
  Chip,
  CircularProgress,
  darken,
  Dialog,
  DialogActions,
  lighten,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import { BiChevronLeft, BiChevronRight, BiTrash } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';

import { theme } from '../../theme';
import {
  deleteAllCollabScans,
  deleteCollabScan,
  getCollab,
} from '../../actions/userActions';
import { capitalizeFullName } from '../../components/Utils/Formating';
import {
  calculateTotalPages,
  calculateTotalScans,
  CustomIconButton,
  StyledButton,
} from '../../components/Utils/UIUtils';
import MonthlyTotalsTable from '../../components/Utils/MonthlyTotalsTable';

const CollabById = () => {
  const { t, i18n } = useTranslation();
  const { collab, loading, error } = useSelector((state) => state.collab);
  const [data, setData] = useState([]);

  const { id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const pageSize = 10;

  const today = Timestamp.now();
  const dateKey = `${today.toDate().getMonth() + 1}-${today
    .toDate()
    .getFullYear()}`;
  const customerThisMonth = calculateCustomerThisMonth(collab?.scans, dateKey);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [scanToDelete, setScanToDelete] = useState(null);
  const deleteScanState = useSelector((state) => state.deleteCollabScan);

  const deleteAllScansState = useSelector(
    (state) => state.deleteAllCollabScans
  );
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const processScans = (scans = {}, t, i18n) => {
    return Object.entries(scans)
      .flatMap(([monthKey, monthScans]) => {
        if (!Array.isArray(monthScans)) {
          const [month, year] = monthKey.split('-');
          const dummyDate = new Date(parseInt(year), parseInt(month) - 1, 15);
          return [
            {
              date: dummyDate.toLocaleDateString(i18n.language),
              time: '-',
              count: monthScans.count || 0,
              monthKey,
              timestamp: dummyDate.getTime(),
              isLegacy: true,
            },
          ];
        }

        return monthScans.map((scan) => ({
          date: new Date(scan.timestamp).toLocaleDateString(i18n.language),
          time: new Date(scan.timestamp).toLocaleTimeString(i18n.language, {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }),
          count: 1,
          monthKey,
          timestamp:
            typeof scan.timestamp === 'number'
              ? scan.timestamp
              : scan.timestamp.toMillis(),
          isLegacy: false,
        }));
      })
      .sort((a, b) => b.timestamp - a.timestamp);
  };

  useEffect(() => {
    if (collab) {
      const processedScans = processScans(collab.scans, t, i18n).slice(
        (page - 1) * pageSize,
        page * pageSize
      );

      const scansData = processedScans.map((scan) =>
        convertCollabScanToData(scan, t, handleDeleteClick)
      );
      setData(scansData);
    }

    if (loading) {
      const skeletonData = Array.from({ length: pageSize }, () =>
        convertCollabScanToSkeleton()
      );
      setData(skeletonData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collab, page, loading, t, i18n.language]);

  const columns = [
    {
      name: 'date',
      label: t('ADMIN.COLLAB_BY_ID.TABLE.DATE'),
      options: {
        setCellProps: () => ({ style: { width: '160px' } }),
        filter: false,
        sort: false,
      },
    },
    {
      name: 'time',
      label: t('ADMIN.COLLAB_BY_ID.TABLE.TIME'),
      options: {
        setCellProps: () => ({ style: { width: '160px' } }),
        filter: false,
        sort: false,
      },
    },
    {
      name: 'count',
      label: t('ADMIN.COLLAB_BY_ID.TABLE.CUSTOMERS'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'actions',
      label: t('ADMIN.COLLAB_BY_ID.TABLE.ACTIONS'),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    download: false,
    elevation: 0,
    print: false,
    filter: false,
    search: false,
    selectableRows: 'none',
    viewColumns: false,
    rowsPerPageOptions: [],
    responsive: 'standard',
    caseSensitive: false,
    customFooter: () => null,
    textLabels: {
      body: {
        noMatch: t('ADMIN.COLLAB_BY_ID.TABLE.NO_MATCH'),
      },
    },
  };

  useEffect(() => {
    if (id) dispatch(getCollab(id));
  }, [dispatch, id]);

  const handleBack = () => {
    if (window.history.length > 2) {
      window.history.back();
    }

    window.location.href = '/collabs';
  };

  const handleDeleteClick = (scan) => {
    setScanToDelete(scan);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (scanToDelete) {
      await dispatch(
        deleteCollabScan(id, scanToDelete.monthKey, {
          timestamp: scanToDelete.timestamp,
        })
      );
      setDeleteDialogOpen(false);
      setScanToDelete(null);
    }
  };

  const handleDeleteAllScans = async () => {
    try {
      await dispatch(deleteAllCollabScans(id));
      setDeleteAllDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const totalScans = calculateTotalScans(collab?.scans);
  const totalPages = calculateTotalPages(totalScans, pageSize);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return error ? (
    <Alert severity="error">{error}</Alert>
  ) : (
    <Stack gap={2}>
      <Stack
        direction={'row'}
        gap={2}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <CustomIconButton onClick={handleBack}>
            <BiChevronLeft size={18} />
          </CustomIconButton>
          <Typography
            variant="h1"
            sx={{
              fontSize: '20px',
              color: 'primary.neutral900',
              fontWeight: '500',
            }}
          >
            <Trans
              i18nKey="ADMIN.COLLAB_BY_ID.TITLE"
              values={{
                name: capitalizeFullName(
                  (collab?.name || ' ') + ' ' + (collab?.surname || ' '),
                  t
                ),
              }}
            />
          </Typography>
        </Stack>

        {isMobile ? (
          <CustomIconButton
            onClick={() => setDeleteAllDialogOpen(true)}
            style={{
              backgroundColor: 'error.main',
              color: 'common.white',
              borderColor: 'error.main',
              '&:hover': {
                backgroundColor: lighten(theme.palette.error.main, 0.1),
                borderColor: lighten(theme.palette.error.main, 0.1),
              },
              '&.Mui-disabled': {
                backgroundColor: theme.palette.primary.neutral200,
                borderColor: theme.palette.primary.neutral200,
                color: theme.palette.primary.neutral600,
              },
            }}
            disabled={!totalScans}
          >
            <BiTrash size={18} />
          </CustomIconButton>
        ) : (
          <StyledButton
            onClick={() => setDeleteAllDialogOpen(true)}
            sx={{
              backgroundColor: 'error.main',
              color: 'common.white',
              '&:hover': {
                backgroundColor: lighten(theme.palette.error.main, 0.1),
              },
              '&.Mui-disabled': {
                backgroundColor: theme.palette.primary.neutral200,
                color: theme.palette.primary.neutral600,
              },
              alignSelf: 'flex-start',
              fontSize: '14px',
            }}
            disabled={!totalScans}
          >
            {t('ADMIN.COLLAB_BY_ID.DELETE_ALL_SCANS')}
          </StyledButton>
        )}
      </Stack>
      <Stack
        sx={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        <Stack
          sx={{
            borderRadius: theme.shape.defaultBorderRadius,
            bgcolor: 'white',
            border: '1px solid',
            borderColor: 'primary.neutral200',
            p: 2,
            flex: 3,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 3,
          }}
        >
          <Stack gap={1.5} sx={{ width: '100%' }}>
            <Typography
              variant="body1"
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              {t('ADMIN.COLLAB_BY_ID.INFO')}
            </Typography>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{
                width: { xs: '70%', sm: '30%' },
                overflow: 'hidden',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {t('ADMIN.COLLAB_BY_ID.CUSTOMERS_LEFT')}
              </Typography>
              <Chip label={collab?.customersCount || 0} size="medium" />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{
                width: { xs: '70%', sm: '30%' },
                overflow: 'hidden',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {t('ADMIN.COLLAB_BY_ID.CUSTOMERS_THIS_MONTH')}
              </Typography>
              <Chip label={customerThisMonth} size="medium" />
            </Stack>
          </Stack>

          <Stack
            gap={2}
            direction={'row'}
            sx={{
              width: '100%',
              flexWrap: 'wrap',
              '& .MuiPaper-root': {
                width: '100%',
                display: 'table',
                tableLayout: 'fixed',
              },
              '& > *': {
                flex: { xs: '1 1 100%', md: '1 1 calc(50% - 16px)' },
              },
            }}
          >
            <Stack gap={1.5}>
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.main',
                  fontSize: '18px',
                  fontWeight: '500',
                }}
              >
                {t('ADMIN.COLLAB_BY_ID.TABLE.TITLE')}
              </Typography>
              <MUIDataTable data={data} columns={columns} options={options} />
              <Stack direction={'row'} gap={1} sx={{ alignSelf: 'center' }}>
                <CustomIconButton
                  disabled={page === 1}
                  onClick={() => setPage((p) => p - 1)}
                >
                  <BiChevronLeft size={18} />
                </CustomIconButton>
                <CustomIconButton
                  disabled={!data.length || page >= totalPages}
                  onClick={handleNextPage}
                >
                  <BiChevronRight size={18} />
                </CustomIconButton>
              </Stack>
            </Stack>

            <Stack gap={1.5}>
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.main',
                  fontSize: '18px',
                  fontWeight: '500',
                }}
              >
                {t('ADMIN.COLLAB_BY_ID.MONTHLY_TOTALS')}
              </Typography>
              <MonthlyTotalsTable scans={collab?.scans} t={t} i18n={i18n} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <DeleteScanDialog
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={handleDeleteConfirm}
        loading={deleteScanState.loading}
        deleteAllLoading={deleteAllScansState?.loading}
        t={t}
      />
      <DeleteScanDialog
        open={deleteAllDialogOpen}
        handleClose={() => setDeleteAllDialogOpen(false)}
        handleConfirm={handleDeleteAllScans}
        loading={deleteScanState.loading}
        deleteAllLoading={deleteAllScansState?.loading}
        t={t}
        isDeleteAll
      />
    </Stack>
  );
};

function convertCollabScanToData(scan, t, onDelete) {
  return {
    date: (
      <Typography
        sx={{
          color: 'primary.neutral800',
          fontSize: '14px',
        }}
      >
        {scan.date}
      </Typography>
    ),
    time: (
      <Typography
        sx={{
          color: 'primary.neutral800',
          fontSize: '14px',
          fontWeight: '500',
        }}
      >
        {scan.isLegacy ? '-' : scan.time}
      </Typography>
    ),
    count: (
      <Typography
        sx={{
          color: 'primary.neutral800',
          fontSize: '14px',
          fontWeight: '500',
        }}
      >
        {scan.isLegacy ? scan.count : 1}
      </Typography>
    ),
    actions: !scan.isLegacy ? (
      <Stack direction="row" gap={1}>
        <Tooltip
          title={t('ADMIN.COLLAB_BY_ID.TABLE.DELETE_SCAN')}
          placement="bottom"
          arrow
        >
          <div>
            <CustomIconButton onClick={() => onDelete(scan)}>
              <BiTrash size={18} color={theme.palette.error.main} />
            </CustomIconButton>
          </div>
        </Tooltip>
      </Stack>
    ) : null,
  };
}

function convertCollabScanToSkeleton() {
  return {
    date: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    time: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    count: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    actions: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
  };
}

const calculateCustomerThisMonth = (scans, currentDateKey) => {
  const monthScans = scans?.[currentDateKey];
  if (!monthScans) return 0;

  if (!Array.isArray(monthScans)) {
    return monthScans.count || 0;
  }

  return monthScans.length;
};

const DeleteScanDialog = ({
  open,
  handleClose,
  handleConfirm,
  loading,
  deleteAllLoading,
  t,
  isDeleteAll = false,
}) => {
  const isLoading = isDeleteAll ? deleteAllLoading : loading;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-scan-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '500px',
        },
      }}
    >
      <Stack
        variant="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          px: 3,
          py: 2,
        }}
      >
        <Typography
          id="delete-scan-dialog-title"
          variant="h3"
          sx={{
            color: 'primary.neutral800',
            fontSize: '24px',
            fontWeight: '600',
            my: 1,
          }}
        >
          {isDeleteAll
            ? t('ADMIN.COLLAB_BY_ID.DELETE_ALL_SCANS_MODAL.TITLE')
            : t('ADMIN.COLLAB_BY_ID.DELETE_SCAN_MODAL.TITLE')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'primary.neutral600',
            fontSize: '16px',
            fontWeight: '400',
          }}
        >
          {isDeleteAll
            ? t('ADMIN.COLLAB_BY_ID.DELETE_ALL_SCANS_MODAL.DESCRIPTION')
            : t('ADMIN.COLLAB_BY_ID.DELETE_SCAN_MODAL.DESCRIPTION')}
        </Typography>
        <DialogActions sx={{ display: 'flex', gap: 1, p: 0 }}>
          <StyledButton
            onClick={handleClose}
            disabled={isLoading}
            sx={{
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'primary.neutral200',
              color: 'primary.neutral600',
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.neutral200, 0.2),
                borderColor: theme.palette.primary.neutral200,
              },
              '&.Mui-disabled': {
                color: 'primary.neutral600',
                backgroundColor: 'primary.neutral100',
                borderColor: 'primary.neutral200',
              },
            }}
          >
            {t('COMMON.CANCEL')}
          </StyledButton>
          <StyledButton
            onClick={handleConfirm}
            disabled={isLoading}
            sx={{
              backgroundColor: 'error.main',
              color: 'common.white',
              gap: 1,
              '&:hover': {
                backgroundColor: lighten(theme.palette.error.main, 0.1),
              },
              '&.Mui-disabled': {
                color: 'common.white',
                backgroundColor: darken(theme.palette.error.main, 0.2),
              },
            }}
          >
            {t('COMMON.DELETE')}
            {isLoading && <CircularProgress size={16} color="inherit" />}
          </StyledButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default CollabById;
