import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  lighten,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  BiChevronLeft,
  BiChevronRight,
  BiHistory,
  BiPencil,
  BiPlus,
  BiShow,
  BiTrash,
} from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  CustomIconButton,
  CustomTextField,
  hasRoles,
  StyledButton,
  UserRoles,
} from '../../../components/Utils/UIUtils';
import { theme } from '../../../theme';
import {
  capitalizeFullName,
  formatDate,
  formatTimeAgo,
} from '../../../components/Utils/Formating';
import {
  listCustomers,
  listCustomersBySearch,
} from '../../../actions/userActions';
import ViewCustomerDialog from './ViewCustomerDialog';
import AddCustomerDialog from './AddCustomerDialog';
import DeleteCustomerDialog from './DeleteCustomerDialog';
import EditCustomerDialog from './EditCustomerDialog';

function convertCustomerToData(
  customer,
  t,
  i18n,
  handleDeleteDialogOpen,
  handleViewDialogOpen,
  handleEditDialogOpen,
  userAuth
) {
  return {
    customer: (
      <Stack gap={0.5}>
        <Typography
          sx={{
            color: 'primary.neutral800',
            fontSize: '14px',
            fontWeight: '500',
            whiteSpace: 'nowrap',
          }}
        >
          {capitalizeFullName(customer.name + ' ' + customer.surname, t)}
        </Typography>
        <Typography
          sx={{
            color: 'primary.neutral500',
            fontSize: '13px',
            whiteSpace: 'nowrap',
          }}
        >
          {formatDate(new Date(customer.dateOfBirth), t)}
        </Typography>
        <Typography
          sx={{
            color: 'primary.neutral500',
            fontSize: '13px',
            whiteSpace: 'nowrap',
          }}
        >
          {customer.phoneNumber}
        </Typography>
      </Stack>
    ),
    address: (
      <Stack gap={0.5}>
        <Typography
          sx={{
            color: 'primary.neutral800',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '16px',
          }}
        >
          {customer.address}
        </Typography>
        <Typography
          sx={{
            color: 'primary.neutral500',
            fontSize: '13px',
            lineHeight: '16px',
          }}
        >
          {customer.city}, {customer.zipcode}
        </Typography>
      </Stack>
    ),
    notes: (
      <Typography
        sx={{
          color:
            customer.notes === '' ? 'primary.neutral500' : 'primary.neutral800',
          fontWeight: '400',
          fontSize: '14px',
        }}
      >
        {customer.notes === ''
          ? t('ADMIN.CUSTOMERS.TABLE.NO_NOTES')
          : customer.notes?.length > 80
          ? customer.notes.slice(0, 80) + '...'
          : customer.notes}
      </Typography>
    ),
    lessonsLeft: (
      <Typography
        sx={{
          color: 'primary.neutral800',
          fontSize: '14px',
          fontWeight: '500',
        }}
      >
        {customer.lessonsLeft}
      </Typography>
    ),
    createdAt: (
      <Stack gap={0.5}>
        <Typography
          sx={{
            color: 'primary.neutral800',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          {formatTimeAgo(customer.createdAt, t)}
        </Typography>
        <Typography
          sx={{
            color: 'primary.neutral500',
            fontSize: '13px',
          }}
        >
          {formatDate(customer.createdAt, t, i18n.language)}
        </Typography>
      </Stack>
    ),
    actions: (
      <Stack gap={1} direction="row">
        <Tooltip
          title={t('ADMIN.CUSTOMERS.TABLE.VIEW')}
          placement="bottom"
          arrow
        >
          <div>
            <CustomIconButton onClick={() => handleViewDialogOpen(customer)}>
              <BiShow size={18} />
            </CustomIconButton>
          </div>
        </Tooltip>
        {hasRoles(userAuth.user, [
          UserRoles.SUPER,
          UserRoles.ADMINISTRATOR,
        ]) && (
          <Tooltip
            title={t('ADMIN.CUSTOMERS.TABLE.VIEW_HISTORY')}
            placement="bottom"
            arrow
          >
            <div>
              <CustomIconButton component={Link} to={`/${customer.id}`}>
                <BiHistory size={18} />
              </CustomIconButton>
            </div>
          </Tooltip>
        )}
        <Tooltip
          title={t('ADMIN.CUSTOMERS.TABLE.EDIT')}
          placement="bottom"
          arrow
        >
          <div>
            <CustomIconButton onClick={() => handleEditDialogOpen(customer)}>
              <BiPencil size={18} />
            </CustomIconButton>
          </div>
        </Tooltip>
        {hasRoles(userAuth.user, [UserRoles.SUPER]) && (
          <Tooltip
            title={t('ADMIN.CUSTOMERS.TABLE.DELETE')}
            placement="bottom"
            arrow
          >
            <div>
              <CustomIconButton
                onClick={() => handleDeleteDialogOpen(customer)}
              >
                <BiTrash size={18} color={theme.palette.error.main} />
              </CustomIconButton>
            </div>
          </Tooltip>
        )}
      </Stack>
    ),
  };
}

function convertCustomerToSkeleton() {
  return {
    customer: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    address: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    notes: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    lessonsLeft: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    createdAt: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    actions: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
  };
}

const useDebounce = (callback, delay) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const debouncedFunction = useCallback(
    (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(() => {
        callback(...args);
      }, delay);

      setTimeoutId(newTimeoutId);
    },
    [callback, delay, timeoutId]
  );

  return debouncedFunction;
};

function Customers() {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const { customers, count, loading } = useSelector(
    (state) => state.listCustomers
  );
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const columns = [
    {
      name: 'customer',
      label: t('ADMIN.CUSTOMERS.TABLE.CUSTOMER'),
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
        filter: false,
        sort: false,
      },
    },
    {
      name: 'address',
      label: t('ADMIN.CUSTOMERS.TABLE.ADDRESS'),
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
        filter: false,
        sort: false,
      },
    },
    {
      name: 'notes',
      label: t('ADMIN.CUSTOMERS.TABLE.NOTES'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'lessonsLeft',
      label: t('ADMIN.CUSTOMERS.TABLE.LESSONS_LEFT'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'createdAt',
      label: t('ADMIN.CUSTOMERS.TABLE.CREATED_AT'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'actions',
      label: t('ADMIN.CUSTOMERS.TABLE.ACTIONS'),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    download: false,
    elevation: 0,
    print: false,
    filter: false,
    search: false,
    selectableRows: 'none',
    viewColumns: false,
    rowsPerPageOptions: [],
    responsive: 'standard',
    caseSensitive: false,
    customFooter: () => null,
    textLabels: {
      body: {
        noMatch: t('ADMIN.CUSTOMERS.TABLE.NO_MATCH'),
      },
    },
  };

  useEffect(() => {
    dispatch(listCustomers('all', pageSize));
  }, [dispatch, pageSize]);

  const handleNextPage = () => {
    const totalPages = Math.ceil(count / pageSize);
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
      dispatch(listCustomers('next', pageSize));
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
      dispatch(listCustomers('prev', pageSize));
    }
  };

  const userAuth = useSelector((state) => state.userLogin.userAuth);

  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [eidtDialogOpen, setEditDialogOpen] = useState(false);

  const [customer, setCustomer] = useState(null);

  const handleViewDialogClose = () => {
    setViewDialogOpen(false);
  };

  const handleViewDialogOpen = (customer) => {
    setCustomer(customer);
    setViewDialogOpen(true);
  };

  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
  };

  const handleAddDialogOpen = (customer) => {
    dispatch({
      type: 'CUSTOMER_CREATION_RESET',
    });
    setCustomer(customer);
    setAddDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogOpen = (customer) => {
    setCustomer(customer);
    setDeleteDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditDialogOpen = (customer) => {
    dispatch({
      type: 'CUSTOMER_UPDATE_RESET',
    });
    setCustomer(customer);
    setEditDialogOpen(true);
  };

  useEffect(() => {
    if (customers) {
      const newData = customers.map((customer) =>
        convertCustomerToData(
          customer,
          t,
          i18n,
          handleDeleteDialogOpen,
          handleViewDialogOpen,
          handleEditDialogOpen,
          userAuth
        )
      );
      setData(newData);
    }
    if (loading) {
      const skeletonData = Array.from({ length: pageSize }, (_, index) =>
        convertCustomerToSkeleton()
      );
      setData(skeletonData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  const debouncedSearch = useDebounce((search) => {
    if (search !== '') {
      dispatch(listCustomersBySearch(search));
    } else {
      dispatch(listCustomers('all', pageSize));
    }
  }, 500);

  const handleSearchChange = (e) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    debouncedSearch(newValue);
  };

  return (
    <Stack gap={2}>
      <Stack
        direction={'row'}
        gap={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: '16px',
            color: 'primary.neutral900',
            fontWeight: '500',
          }}
        >
          {t('ADMIN.CUSTOMERS.TITLE') +
            ' (' +
            (count === undefined ? 0 : count) +
            ')'}
        </Typography>

        {hasRoles(userAuth.user, [UserRoles.SUPER]) &&
          (isMobile ? (
            <CustomIconButton
              onClick={() => handleAddDialogOpen(customer)}
              style={{
                backgroundColor: theme.palette.primary.main,
                color: 'common.white',
                borderColor: theme.palette.primary.main,
                p: 1,
                '&:hover': {
                  backgroundColor: lighten(theme.palette.primary.main, 0.2),
                  borderColor: lighten(theme.palette.primary.main, 0.2),
                },
              }}
            >
              <BiPlus size={18} />
            </CustomIconButton>
          ) : (
            <StyledButton
              variant="contained"
              startIcon={<BiPlus size={18} />}
              onClick={() => handleAddDialogOpen(customer)}
              sx={{ fontSize: '14px' }}
            >
              {t('ADMIN.CUSTOMERS.ADD_CUSTOMER')}
            </StyledButton>
          ))}
      </Stack>

      <Stack
        sx={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        <Stack
          sx={{
            borderRadius: theme.shape.defaultBorderRadius,
            bgcolor: 'white',
            border: '1px solid',
            borderColor: 'primary.neutral200',
            p: 2,
            flex: 3,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <Stack gap={1} sx={{ width: '100%' }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: '14px',
                color: 'primary.neutral600',
                fontWeight: '500',
              }}
            >
              {t('ADMIN.CUSTOMERS.SEARCH_FOR_CUSTOMERS')}
            </Typography>
            <CustomTextField
              placeholder={t('ADMIN.CUSTOMERS.SEARCH_PLACEHOLDER')}
              name="searchInput"
              id="searchInput"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.neutral200,
                  },
                },
              }}
            />
          </Stack>
          <Box
            sx={{
              width: '100%',
              '& .MuiPaper-root': {
                width: '100%',
                display: 'table',
                tableLayout: 'fixed',
              },
            }}
          >
            <MUIDataTable data={data} columns={columns} options={options} />
          </Box>
          <Stack direction={'row'} gap={1} sx={{ alignSelf: 'center' }}>
            <CustomIconButton
              disabled={page === 1}
              onClick={handlePreviousPage}
            >
              <BiChevronLeft size={18} />
            </CustomIconButton>
            <CustomIconButton
              disabled={page === Math.ceil(count / pageSize) || count === 0}
              onClick={handleNextPage}
            >
              <BiChevronRight size={18} />
            </CustomIconButton>
          </Stack>
        </Stack>
      </Stack>
      <ViewCustomerDialog
        viewDialogOpen={viewDialogOpen}
        handleViewDialogClose={handleViewDialogClose}
        customer={customer}
        theme={theme}
        dispatch={dispatch}
        t={t}
      />
      <AddCustomerDialog
        addDialogOpen={addDialogOpen}
        handleAddDialogClose={handleAddDialogClose}
        customer={customer}
        theme={theme}
        dispatch={dispatch}
        t={t}
      />
      <DeleteCustomerDialog
        deleteDialogOpen={deleteDialogOpen}
        handleDeleteDialogClose={handleDeleteDialogClose}
        customer={customer}
        theme={theme}
        dispatch={dispatch}
        t={t}
      />
      <EditCustomerDialog
        editDialogOpen={eidtDialogOpen}
        handleEditDialogClose={handleEditDialogClose}
        customerData={customer}
        theme={theme}
        dispatch={dispatch}
        t={t}
      />
    </Stack>
  );
}

export default Customers;
