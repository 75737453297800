import {
  Button,
  styled,
  InputBase,
  TextField,
  Box,
  CircularProgress,
  circularProgressClasses,
  Link,
  IconButton,
  alpha,
} from '@mui/material';
import { BiShapeCircle, BiUser, BiSpa } from 'react-icons/bi';

import { theme } from '../../theme';

export const getTransitionStyle = (theme, properties) => {
  const transitionProperties = properties.map((property) => {
    return `${property} ${theme.transitions.duration.standard} ${theme.transitions.easing.easeInOut}`;
  });

  return {
    transition: transitionProperties.join(', '),
  };
};

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.main,
  fontWeight: '400',
  textDecoration: 'none',
  fontSize: '15px',
  whiteSpace: 'nowrap',
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  ...getTransitionStyle(theme, ['color']),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '8px 12px',
  fontSize: '15px',
  fontWeight: '400',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...getTransitionStyle(theme, ['background-color']),
}));

export const CustomTextField = styled(TextField)(({ theme, sx }) => ({
  ...sx,
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.defaultBorderRadius,
    position: 'relative',
    backgroundColor: theme.palette.primary.white,
    fontSize: 16,
    width: '100%',
    '& fieldset': {
      ...getTransitionStyle(theme, ['border-color']),
      border: '1px solid',
      borderColor: theme.palette.primary.neutral300,
    },
    '&:hover fieldset': {
      border: '1px solid',
      borderColor: theme.palette.primary.neutral300,
    },
    '&.Mui-focused fieldset': {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.neutral100,
    },
  },
  '& .MuiInputBase-input': {
    minHeight: '24px',
    padding: '10px 12px',
  },
}));

export const TextArea = styled(InputBase)(({ theme }) => ({
  width: '100%',
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    minHeight: '24px',
    borderRadius: theme.shape.defaultBorderRadius,
    position: 'relative',
    backgroundColor: theme.palette.primary.white,
    border: '1px solid',
    borderColor: theme.palette.primary.neutral300,
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    resize: 'vertical',
    ...getTransitionStyle(theme, [
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& textarea': { resize: 'vertical' },
}));

export const WebsiteLoader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: 'primary.main',
          animationDuration: '550ms',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
      />
    </Box>
  );
};

export const hasRoles = (user, roles) => {
  if (!user || !user.roles) {
    return false;
  }
  return roles.some((role) => user.roles.includes(role));
};

export const UserRoles = {
  CUSTOMER: 'customer',
  ADMINISTRATOR: 'admin',
  SUPER: 'super',
  GUEST: 'guest',
  SUBSCRIBER: 'subscriber',
  COLLAB: 'collab',
};

export const Providers = {
  email: 'email',
  google: 'google',
  facebook: 'facebook',
  twitter: 'twitter',
};

export const links = [
  {
    to: '/',
    label: 'MAIN_DRAWER.CUSTOMERS',
    order: 1,
    subItems: [],
    roles: [UserRoles.ADMINISTRATOR, UserRoles.SUPER],
    icon: (
      <BiUser
        style={{
          fontSize: '19px',
        }}
      />
    ),
  },
  {
    to: '/collabs',
    label: 'MAIN_DRAWER.COLLABS',
    order: 2,
    subItems: [],
    roles: [UserRoles.SUPER],
    icon: (
      <BiShapeCircle
        style={{
          fontSize: '19px',
        }}
      />
    ),
  },
  {
    to: '/collab',
    label: 'MAIN_DRAWER.COLLAB',
    order: 2,
    subItems: [],
    roles: [UserRoles.COLLAB],
    icon: (
      <BiSpa
        style={{
          fontSize: '19px',
        }}
      />
    ),
  },
];

export const CustomIconButton = ({ children, style, ...props }) => {
  return (
    <IconButton
      sx={{
        borderRadius: theme.shape.defaultBorderRadius,
        border: '1px solid',
        borderColor: 'primary.neutral200',
        p: 0.8,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.neutral100, 0.5),
        },
        ...style,
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
};

export const calculateTotalScans = (scans = {}) => {
  return Object.values(scans).reduce(
    (acc, curr) => acc + (Array.isArray(curr) ? curr.length : 1),
    0
  );
};

export const calculateTotalPages = (totalItems, pageSize) => {
  return Math.ceil(totalItems / pageSize);
};
